import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";

import widgetHelper from "../../../helpers/widget";
import { openSidebar } from "../../../redux/actions-exporter";

import "./GetGift.css";

function GetGift() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);

  return (
    <div className="getGift">
      {/* <img loading="lazy" src={gift} /> */}
      <Form className="form getGiftForm">
        <Form.Group
          className="mb-3 getGiftFormGroup"
          controlId="formBasicPassword"
        >
          <Form.Label>קבלת שובר</Form.Label>
          <Form.Control type="tel" placeholder="הכנס את מספר הנייד שלך" />
        </Form.Group>
        <Button
          onClick={() => {
            if (!user) {
              widgetHelper.login(
                () => dispatch(openSidebar())
                // () => navigate("/login")
              );
            } else {
              navigate("/wallet");
            }
          }}
          variant="info"
        >
          המשך
        </Button>
      </Form>
    </div>
  );
}

export default GetGift;
