import { MerchantsActionTypes } from "./merchant.types";
import { axiosNoTokenInstance } from "../../axios.config";

export const getMerchants =
  (
    longitude,
    latitude,
    radius,
    offset = 0,
    limit = 10,
    domain = process.env.REACT_APP_API_DOMAIN,
    type
  ) =>
  (dispatch) => {
    dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_PENDING });

    const query =
      `/merchant/preset1?domain=${domain}${
        longitude ? `&longitude=${longitude}` : ""
      }` +
      `${latitude ? `&latitude=${latitude}` : ""}${
        radius ? `&radius=${radius}` : ""
      }` +
      `${offset >= 0 ? `&offset=${offset}` : ""}${
        limit ? `&limit=${limit}` : ""
      }` +
      `${type ? `&type=${type}` : ""}`;

    axiosNoTokenInstance
      .get(query)
      .then((res) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_ERROR,
          payload: error.response.data,
        })
      );
  };

export const getClubs =
  (
    longitude,
    latitude,
    radius,
    offset = 0,
    limit = 10,
    domain = process.env.REACT_APP_API_DOMAIN
  ) =>
  (dispatch) => {
    // TODO: crash?! dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_PENDING });

    const query =
      `/merchant/preset1?domain=${domain}${
        longitude ? `&longitude=${longitude}` : ""
      }` +
      `${latitude ? `&latitude=${latitude}` : ""}${
        radius ? `&radius=${radius}` : ""
      }&type=hr`;
    // `${offset >= 0 ? `&offset=${offset}` : ""}${
    //     limit ? `&limit=${limit}` : ""
    // }`;

    axiosNoTokenInstance
      .get(query)
      .then((res) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_ERROR,
          payload: error.response.data,
        })
      );
  };

export const getMerchantById =
  (merchantId, domain = process.env.REACT_APP_API_DOMAIN) =>
  (dispatch) => {
    dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_PENDING });

    axiosNoTokenInstance
      .get(`/merchant/${merchantId}?domain=${domain}`)
      .then((res) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_BY_ID_ERROR,
          payload: error.response.data,
        })
      );
  };

export const getMerchantProducts =
  (merchantId, domain = process.env.REACT_APP_API_DOMAIN) =>
  (dispatch) => {
    dispatch({ type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_PENDING });

    axiosNoTokenInstance
      .get(`/merchant/${merchantId}/products?domain=${domain}`)
      .then((res) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: MerchantsActionTypes.GET.GET_MERCHANT_PRODUCTS_ERROR,
          payload: error.response.data,
        })
      );
  };
export const setMerchantFilter = (filter) => (dispatch) => {
  dispatch({
    type: MerchantsActionTypes.SET.MERCHANT_FILTER,
    payload: filter,
  });
};
