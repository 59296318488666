import { TabbingTypes } from "./tabbing.types";

export function tabbingReducer(
  state = {
    tabbingValue: 0,
    b2b: true,
  },
  action
) {
  switch (action.type) {
    case TabbingTypes.SET_TABBING_VALUE:
      return {
        ...state,
        tabbingValue: action.data,
      };
    case TabbingTypes.SET_B2B_VALUE:
      return {
        ...state,
        b2b: action.data,
      };
    default:
      return state;
  }
}

export default tabbingReducer;
