import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
// import "swiper/modules/pagination/pagination.min.css";
// import "swiper/modules/navigation/navigation.min.css";
// import "swiper/modules/autoplay/autoplay.min.css";
// import "swiper/modules/effect-fade/effect-fade.min.css";
import nis from "../../assets/images/nis.png";
import cx from "classnames";
import { useEffect, useState } from "react";

export default function Categories({
  handleChange,
  categories,
  currentCategory,
}) {
  const [sortedCategories, setCategories] = useState([]);
  useEffect(() => {
    if (categories) {
      //disgusting bad workaround code to handle category ordering. Needs to be fixed
      const indexToSwap1 = categories.findIndex((obj) => obj.id === 22);
      const indexToSwap2 = categories.findIndex((obj) => obj.id === 12);
      let tempArray = [...categories];

      if (indexToSwap1 !== -1 && indexToSwap2 !== -1) {
        const temp = tempArray[indexToSwap1];
        tempArray[indexToSwap1] = tempArray[indexToSwap2];
        tempArray[indexToSwap2] = temp;
      }
      setCategories(tempArray);
    }
  }, [categories]);
  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={10}
      centeredSlides={false}
      centerInsufficientSlides={true}
      speed={1000}
      navigation
      modules={[Navigation]}
    >
      {[{ title: "הכל", id: "all", image: nis }, ...sortedCategories]?.map(
        (category, index) => (
          <SwiperSlide
            style={{ width: "120px" }}
            onClick={() => handleChange(category.id)}
            className="categoryList__block isLink"
          >
            <div className="category-box2 text-center">
              {/* <img loading="lazy" alt="" src={category.image} style={{ width: "30px" }} /> */}

              <h6
                style={{
                  margin: 0,
                  border: "1px solid lightgray",
                  padding: 4,
                  background:
                    currentCategory === category.id
                      ? "var(--primary)"
                      : "transparent",
                  color: currentCategory === category.id ? "white" : "black",
                }}
              >
                {category.title}
              </h6>
            </div>
          </SwiperSlide>
        )
      )}
      <SwiperSlide style={{ width: "10px" }}></SwiperSlide>
    </Swiper>
  );
}
