import { CartActionTypes } from "./cart.types";
import { axiosInstance } from "../../axios.config";

/* const getCartCount = (cartItems) =>
  cartItems.reduce((p, c) => p + c.quantity, 0); */

export const getCart = () => (dispatch) => {
  dispatch({ type: CartActionTypes.GET_CART_PENDING });

  axiosInstance
    .get(`/cart`)
    .then((res) =>
      dispatch({
        type: CartActionTypes.GET_CART_SUCCESS,
        payload: res.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: CartActionTypes.GET_CART_ERROR,
        payload: error.response.data,
      })
    );
};

export const addToCart =
  (productId, quantity, price = null, recipients, bonusRuleId = null) =>
  (dispatch) => {
    dispatch({ type: CartActionTypes.GET_CART_PENDING });

    axiosInstance
      .post("/cart", {
        productId,
        quantity,
        price,
        bonusRuleId,
        recipients,
      })
      .then((res) => {
        dispatch({
          type: CartActionTypes.GET_CART_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: CartActionTypes.GET_CART_COUNT_SUCCESS,
          payload: res.data.cartCount,
        });
      })
      .catch((error) =>
        dispatch({
          type: CartActionTypes.GET_CART_ERROR,
          payload: error.response.data,
        })
      );
  };

export const updateCart = (itemId, quantity, recipients) => (dispatch) => {
  dispatch({ type: CartActionTypes.GET_CART_PENDING });

  axiosInstance
    .put("/cart", {
      itemId,
      quantity,
      recipients,
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: CartActionTypes.GET_CART_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: CartActionTypes.GET_CART_COUNT_SUCCESS,
        payload: res.data.cartCount,
      });
    })
    .catch((error) =>
      dispatch({
        type: CartActionTypes.GET_CART_ERROR,
        payload: error.response.data,
      })
    );
};

export const checkout =
  (cardId = null, successCallback) =>
  (dispatch) => {
    dispatch({ type: CartActionTypes.POST.CHECKOUT_PENDING });

    axiosInstance
      .post("/cart/checkout", { cardId })
      .then((res) => {
        dispatch({
          type: CartActionTypes.POST.CHECKOUT_SUCCESS,
          payload: res.data.cart,
        });
        dispatch({
          type: CartActionTypes.GET_CART_COUNT_SUCCESS,
          payload: res.data.cartCount,
        });

        if (typeof successCallback === "function") {
          successCallback(res.data);
        }
      })
      .catch((error) =>
        dispatch({
          type: CartActionTypes.POST.CHECKOUT_ERROR,
          payload: error.response.data,
        })
      );
  };

export const removeItemFromCart = (itemId) => (dispatch) => {
  dispatch({ type: CartActionTypes.GET_CART_PENDING });

  axiosInstance
    .delete(`/cart/${itemId}`)
    .then((res) => {
      dispatch({
        type: CartActionTypes.GET_CART_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: CartActionTypes.GET_CART_COUNT_SUCCESS,
        payload: res.data.cartCount,
      });
    })
    .catch((error) =>
      dispatch({
        type: CartActionTypes.GET_CART_ERROR,
        payload: error.response.data,
      })
    );
};

export const clearCart = () => (dispatch) => {
  dispatch({ type: CartActionTypes.GET_CART_PENDING });

  axiosInstance
    .delete(`/cart`)
    .then((res) => {
      dispatch({
        type: CartActionTypes.GET_CART_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: CartActionTypes.GET_CART_COUNT_SUCCESS,
        payload: res.data.cartCount,
      });
    })
    .catch((error) =>
      dispatch({
        type: CartActionTypes.GET_CART_ERROR,
        payload: error.response.data,
      })
    );
};

export const oneInClickCheckout =
  (data, successCallback, failCallback) => (dispatch) => {
    dispatch({ type: CartActionTypes.POST.ONE_CLICK_CHECKOUT_PENDING });

    axiosInstance
      .post("/cart/checkout/oneclick", data)
      .then((res) => {
        dispatch({
          type: CartActionTypes.POST.ONE_CLICK_CHECKOUT_SUCCESS,
          payload: res.data,
        });

        if (typeof successCallback === "function") {
          successCallback(res.data);
        }
      })
      .catch((error) => {
        dispatch({
          type: CartActionTypes.POST.ONE_CLICK_CHECKOUT_ERROR,
          payload: error.response.data,
        });

        if (typeof failCallback === "function") {
          failCallback(error.response.data);
        }
      });
  };
