import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { IconButton, Drawer } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import Categories from "./Categories";
import { getProducts } from "../../redux/actions-exporter";
import ClubRegistr from "./clubRegistr/ClubRegistr";
import BasicPagination from "./BasicPagination";
import Search from "./Search";
// import VendorList from "./VendorList";
// import shach from "../../assets/images/shach.jpg";
import { openSidebar } from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";
import Send from "../product/Send";
// import SendOne from "../product/SendOne";
// import QuantitySelector from "../quantitySelector";
// import { set } from "date-fns";
// import { Alert, AlertTitle } from "@mui/lab";
import ProductCard from "./ProductCard";
import BuyInOneClick from "../product/BuyInOneClick";

import banner from "../../assets/images/banner_main.jpg";
import "./dashboard.css";

const screenWidth = window.innerWidth;
let makeProductsPerPage = 0;
if (screenWidth > 991) {
  makeProductsPerPage = 12;
} else if (screenWidth > 767) {
  makeProductsPerPage = 8;
} else if (screenWidth > 500) {
  makeProductsPerPage = 6;
} else {
  makeProductsPerPage = 12;
}

const s = {
  dialogContainer: {
    textAlign: "right",
    width: "400px",
    m: "auto",
  },
  dialogTitle: {
    padding: "16px 50px",
  },
  dialogIconButton: {
    position: "absolute",
    left: 8,
    top: 8,
    zIndex: "10000",
  },
};

export default function Dashboard({ setIsClub, isMap }) {
  const cart = useSelector((state) => state.cart.cart);
  const [inCart, setInCart] = useState(false);
  const [cartPop, setCartPop] = useState(false);
  const [buyInOneClick, setBuyInOneClick] = useState(false);
  const [search, setSearch] = useState(false); // eslint-disable-line
  const [currentCategory, setCurrentCategory] = useState("all");
  const [clearFilter, setClearFilter] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [BalanceTitleFont, setBalanceTitleFont] = useState("10vw"); // eslint-disable-line
  const [pop, setPop] = useState(false);
  const [zero, setZero] = useState(false);
  const [tooLow, setTooLow] = useState(null);
  const [tooHigh, setTooHigh] = useState(null);
  const [chosen, setChosen] = useState({ product: {}, price: "", quantity: 1 });
  const [productsPerPage, setProductsPerPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilterProducts, setCurrentFilterProducts] =
    useState(filterProducts);
  const [dis, setDis] = useState(4);
  const geo = useSelector((state) => state.user.geo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const products = useSelector((state) =>
  //   state.product?.products?.sort((obj1, obj2) => {
  //     if (obj1?.attributeValues?.order) {
  //       return -1 * obj1?.attributeValues?.order;
  //     }
  //   })
  // );
  const products = useSelector((state) => state.product?.products);
  // console.log(products);
  const user = useSelector((state) => state?.user?.user);
  const merchants = useSelector((state) => state.merchant.merchants);
  const b2b = useSelector((state) => state.tabbing.b2b);
  // console.log(currentCategory);
  useEffect(() => {
    setCurrentCategory("all");
  }, [b2b]);

  useEffect(() => {
    if (!products.length) dispatch(getProducts());
  }, [dispatch]);

  useEffect(() => {
    if (!b2b) {
      setFilterProducts(
        products?.filter(
          (product) => !(product.price === 0 && product.priceB2B > 0)
        )
      );
    } else {
      setFilterProducts(products);
    }
    setCategories([]);
    if (products?.length) {
      const productCategories = new Map();
      products?.forEach((product) =>
        product?.categories?.forEach((category) =>
          productCategories?.set(category.id, category)
        )
      );
      setCategories([...productCategories.values()]);
    }

    // if (!merchants.length) dispatch(getMerchants());
  }, [products, b2b]); // eslint-disable-line
  // useEffect(() => {
  //   if (categories?.length) {
  //     console.log('d');
  //     const ranges = [
  //       100, 5000, 10000, 50000,
  //       // 0
  //       10000000000,
  //     ];
  //     dispatch(getMerchants(geo.longitude, geo.latitude, ranges[dis]));
  //   }
  // }, [dis, geo.longitude, geo.latitude, categories]);

  useEffect(() => {
    setProductsPerPage(makeProductsPerPage);
  }, [currentPage]);

  useEffect(() => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    setCurrentFilterProducts(
      filterProducts?.slice(indexOfFirstProduct, indexOfLastProduct)
    );
  }, [currentPage, filterProducts, productsPerPage]);

  const handleChange = (catId) => {
    setCurrentPage(1);
    if (currentCategory !== catId && catId !== "all") setClearFilter(true);
    setCurrentCategory(catId);
    if (catId === "all") {
      setFilterProducts(products);
    } else {
      setFilterProducts(
        products.filter(
          (product) =>
            product.categories.findIndex((category) => category.id === catId) >
            -1
        )
      );
    }
  };

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current.offsetWidth < 500) {
      setBalanceTitleFont("67px");
    }
  }, [ref.current]); // eslint-disable-line

  const handleSubmit = (product) => {
    const { minPrice, maxPrice } = product;
    if (cart?.items?.some((a) => a.product.id === product.id)) {
      setInCart(true);
    } else {
      if (
        product.priceB2B > 0 ||
        (!minPrice && !maxPrice) ||
        (!maxPrice && minPrice && chosen.price >= minPrice) ||
        (!minPrice && maxPrice && chosen.price <= maxPrice) ||
        (chosen.price >= minPrice && chosen.price <= maxPrice)
      ) {
        setCartPop(true);
        setChosen({
          ...chosen,
          product: product,
        });
      } else {
        if (!chosen?.price) {
          setZero(true);
        } else {
          chosen.price >= minPrice ? setTooHigh(maxPrice) : setTooLow(minPrice);
        }
      }
    }
  };

  const buttonOnClick = (product, quantity = 1) => {
    chosen?.product?.id !== product.id &&
      setChosen({
        ...chosen,
        product,
        quantity,
      });

    if (!user) {
      setBuyInOneClick(true);
    } else {
      handleSubmit(product);
    }
  };

  const popular = merchants?.filter(
    (merchant) =>
      merchant.id === 809 || merchant.id === 817 || merchant.id === 789
  );

  !isMap && setIsClub(false);

  // console.log(currentFilterProducts);
  return (
    <>
      {/* <Alert severity="error" style={{ direction: "rtl" }}>
        <AlertTitle style={{ direction: "rtl", textAlign: "right" }}>
          הודעה חשובה!
        </AlertTitle>
        <strong>
          לקוחות יקרים, אתר רעיונית בתהליכי בנייה ושדרוג. תודה על ההבנה.
        </strong>
      </Alert> */}
      <div className="dashboard-tamplate">
        <div className="container" ref={ref}>
          <div className="bannerWrapper">
            <div className="banner" style={{ position: "relative" }}>
              <div className="bannerImg">
                <img alt="" src={banner} />
              </div>
              <div class="filterWrapper">
                {screenWidth > 700 && (
                  <Search
                    filterProducts={filterProducts}
                    setFilterProducts={setFilterProducts}
                    handleChange={handleChange}
                    clearFilter={clearFilter}
                    setClearFilter={setClearFilter}
                  />
                )}
              </div>
            </div>
          </div>
          <IconButton
            sx={{
              display: { sm: "none", xs: "flex" },
              position: "absolute",
              top: "20px",
              right: "65px",
            }}
            onClick={() => setSearch(true)}
          >
            <SearchIcon />
          </IconButton>

          <Categories
            categories={categories}
            handleChange={handleChange}
            currentCategory={currentCategory}
          />

          <div className="product-block">
            <ul className="product-Module">
              {currentFilterProducts.length > 0
                ? currentFilterProducts
                    // .filter((product) => !!product)
                    .filter((product) =>
                      !b2b
                        ? product.priceB2B === 0 || product.price > 0
                        : product
                    )
                    .sort((obj1, obj2) => {
                      if (obj1?.attributeValues?.order) {
                        return -1 * obj1?.attributeValues?.order;
                      }
                    })
                    .map((product) => (
                      <ProductCard
                        product={product}
                        b2b={b2b}
                        buttonOnClick={buttonOnClick}
                        chosen={chosen}
                        setChosen={setChosen}
                        key={product.id}
                      />
                    ))
                : t("Loading, please wait...")}
            </ul>
            <BasicPagination
              productsPerPage={productsPerPage}
              totalProducts={filterProducts.length}
              paginate={(e, page) => setCurrentPage(page)}
            />
          </div>
          {/*<VendorList popular={popular} />*/}
        </div>
        <ClubRegistr />
      </div>

      <Dialog sx={s.dialogContainer} onClose={() => setZero(false)} open={zero}>
        <DialogTitle sx={s.dialogTitle}>נא להכניס סכום</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setZero(false)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        sx={s.dialogContainer}
        onClose={() => setTooLow(null)}
        open={Boolean(tooLow)}
      >
        <DialogTitle sx={s.dialogTitle}>סכום מינימלי: {tooLow} ₪ </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTooLow(null)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        sx={s.dialogContainer}
        onClose={() => setTooHigh(null)}
        open={Boolean(tooHigh)}
      >
        <DialogTitle sx={s.dialogTitle}>סכום מקסימלי: {tooHigh} ₪ </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTooHigh(null)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Dialog
        sx={s.dialogContainer}
        onClose={() => setInCart(false)}
        open={inCart}
      >
        <DialogTitle>
          הפריט כבר נמצא בעגלה {""}
          <Button
            onClick={() => navigate("/cart")}
            variant="contained"
            sx={{ mr: 3 }}
          >
            לעגלה
          </Button>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setInCart(false)}
          sx={s.dialogIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>

      <Drawer
        open={search}
        onClose={() => setSearch(false)}
        PaperProps={{
          sx: {
            padding: "0 24px 16px 24px",
            width: "90%",
            height: "300px",
            top: "120px",
            right: "5%",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            margin: "auto",
          },
        }}
        anchor="right"
      >
        <IconButton
          onClick={() => setSearch(false)}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            zIndex: "10000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Search
          filterProducts={filterProducts}
          setFilterProducts={setFilterProducts}
          handleChange={handleChange}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
        />
      </Drawer>
      <Dialog
        onClose={() => setCartPop(false)}
        open={cartPop}
        maxWidth="xl"
        fullWidth
        sx={{
          textAlign: "center",
        }}
      >
        <DialogTitle></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setCartPop(false)}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            zIndex: "10000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Send chosen={chosen} setChosen={setChosen} />
      </Dialog>
      <BuyInOneClick
        chosen={chosen}
        buyInOneClick={buyInOneClick}
        setBuyInOneClick={setBuyInOneClick}
      />
    </>
  );
}
