import { ProductsActionTypes } from "./product.types";
import { axiosNoTokenInstance } from "../../axios.config";

export const getProducts =
  (domain = process.env.REACT_APP_API_DOMAIN) =>
  (dispatch) => {
    dispatch({ type: ProductsActionTypes.GET.GET_PRODUCT_PENDING });

    axiosNoTokenInstance
      .get(`/product?relations=merchants.region,categories&domain=${domain}`)
      // &relations=merchants.region`)
      .then((res) =>
        dispatch({
          type: ProductsActionTypes.GET.GET_PRODUCT_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: ProductsActionTypes.GET.GET_PRODUCT_ERROR,
          payload: error.response.data,
        })
      );
  };

export const getProductById =
  (productId, domain = process.env.REACT_APP_API_DOMAIN) =>
  (dispatch) => {
    dispatch({ type: ProductsActionTypes.GET.GET_PRODUCT_BY_ID_PENDING });

    axiosNoTokenInstance
      .get(`/product/${productId}?domain=${domain}`)
      .then((res) =>
        dispatch({
          type: ProductsActionTypes.GET.GET_PRODUCT_BY_ID_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: ProductsActionTypes.GET.GET_PRODUCT_BY_ID_ERROR,
          payload: error.response.data,
        })
      );
  };

export const setProductFilter = (filter) => (dispatch) => {
  dispatch({
    type: ProductsActionTypes.SET.PRODUCT_FILTER,
    payload: filter,
  });
};
