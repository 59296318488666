import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  FormGroup,
  Box,
} from "@mui/material";
import { FormControl } from "@mui/material";
import { toast } from "react-toastify";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import Header from "../header/Header";
import {
  checkout,
  getCards,
  addCard,
  removeCard,
} from "../../redux/actions-exporter";

import "../cart/checkout.css";
// import Visa from "../../assets/images/visa.jpg";
// import MasterCard from "../../assets/images/mastercard.png";
import Debitcard from "../../assets/images/debitcard1.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { cardNumberMask, cardDateMask } from "./inputMask";
import CircularIndeterminate from "../loader/CircularIndeterminate";

const LEFT_TO_RIGHT_MARK = "‎\u200e";

function Checkout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { cart, isCheckoutPending } = useSelector((state) => state.cart);
  const today = dayjs();

  const [card, setCard] = useState({});
  // const [submit, setSubmit] = useState(false);
  const [showaddress, setShowAddress] = useState(false);
  const [showcard, setShowCard] = useState(false);
  const [removedCard, setRemovedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("pelecard-iframe");
  const { cards, addCardError } = useSelector((state) => state.card);

  useEffect(() => {
    dispatch(getCards());
  }, []); // eslint-disable-line
  useEffect(() => {
    if (addCardError) {
      toast.error(t(addCardError.message || "שגיאת הוספת כרטיס"));
    } else {
      setShowCard(false);
      setCard({});
    }
  }, [addCardError]);

  useEffect(() => {
    setShowCard(false);
  }, [cards]);

  const onCheckout = () => {
    setCard({});
    dispatch(
      checkout(
        paymentMethod === "pelecard-iframe" ? null : paymentMethod,
        (data) => {
          const { paymentURL, isPaid, orderId, message } = data;
          if (paymentURL) {
            navigate("/checkout/pay", { state: { paymentURL } });
          } else {
            navigate(
              isPaid
                ? `/checkout/success/${orderId}`
                : `/checkout/error/${orderId}`,
              {
                state: {
                  message,
                },
              }
            );
          }
        }
      )
    );
  };
  const handleOpenModal = (id) => () => {
    setShowModal(true);
    setRemovedCard(id);
  };
  const handleRemoveCard = () => {
    dispatch(removeCard(removedCard));
    setShowModal(false);
  };
  const handleChangeExpiryDate = (e) => {
    const processedDate = e.target.value
      .replace(LEFT_TO_RIGHT_MARK, "")
      .replace(/\D/g, "")
      .slice(0, 4);
    setCard({ ...card, expirationDate: processedDate });
  };
  const handleChangeCardNumber = (e) => {
    const processedNumber = e.target.value
      .replace(LEFT_TO_RIGHT_MARK, "")
      .replace(/\D/g, "")
      .slice(0, 16);
    setCard({ ...card, number: processedNumber });
  };
  const handleChangeCVC = (e) => {
    const processedCVC = e.target.value.replace(/\D/g, "").slice(0, 3);
    setCard({ ...card, cvv2: processedCVC });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(card);
    dispatch(addCard(card));
  };
  // console.log(cards);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          textAlign: "center",
          width: "500px",
          maxWidth: "90vw",
          margin: "auto",
        }}
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            {/* {"Card removal request"} */}
            הסרת כרטיס
          </DialogTitle>
          <DialogContentText id="alert-dialog-description">
            {/* {"Are you sure you want to delete this card?"} */}
            האם את/ה בטוח/ה שברצונך להסיר את הכרטיס הזה?
          </DialogContentText>
          <DialogActions
          // sx={{ justifyContent: "flex-start" }}
          >
            <Button onClick={handleRemoveCard}>
              {/* Confirm */}
              אישור
            </Button>
            <Button onClick={() => setShowModal(false)}>
              {/* Cancel */}
              ביטול
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <div>
        <div className="pageTemplate">
          <div className="container">
            {!showaddress && !showcard ? (
              <div>
                <div className="templateHeading">
                  <span className="backIcon isLink">
                    <ArrowBackIcon
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                  </span>
                  <h1 className="templateTitle">{t("checkout")}</h1>
                </div>
                {/* <div className="checkoutBlocks">
                  <h5 className="checkoutBlocks-title">{t("deliveryAddress")}</h5>
                  <div className="row align-items-center">
                    <div className="col-lg-4">
                      <TextField
                        id="outlined-basic"
                        label={t("homeAddress")}
                        variant="outlined"
                        placeholder={t("homeAddress")}
                      />
                    </div>
                    <div className="col-lg-12 text-center">
                      <Button
                        variant="text"
                        onClick={() => setShowAddress(true)}
                        className="LinkBtn"
                      >
                        <AddIcon /> {t("newAddress")}
                      </Button>
                    </div>
                  </div>
                </div> */}
                <div className="checkoutBlocks">
                  <h5 className="checkoutBlocks-title">
                    {" "}
                    {t("choosePaymentMethod")}
                  </h5>
                  <div className="row justify-content-center">
                    <div
                      className="col-lg-4"
                      onClick={() => setPaymentMethod("pelecard-iframe")}
                    >
                      <div
                        className={cx("PaymentCards", {
                          PaymentCards__selected:
                            paymentMethod === "pelecard-iframe",
                        })}
                      >
                        <span className="PaymentCards-no">
                          {t("Pelecard (iframe)")}
                        </span>
                      </div>
                    </div>
                    {cards.map((card) => (
                      <div
                        className="col-lg-4"
                        onClick={() => setPaymentMethod(card.id)}
                      >
                        <div
                          className={cx("PaymentCards", {
                            PaymentCards__selected: paymentMethod === card.id,
                          })}
                        >
                          {/*<div className="PaymentCards-img">*/}
                          {/*  <img loading="lazy" */}
                          {/*    src={Visa}*/}
                          {/*    alt=""*/}
                          {/*    height={100}*/}
                          {/*    width={100}*/}
                          {/*    className="img-fluid"*/}
                          {/*  />*/}
                          {/*</div>*/}
                          <span className="PaymentCards-no">{card.number}</span>
                          <DeleteIcon
                            className="PaymentCards__delete"
                            onClick={handleOpenModal(card.id)}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="col-lg-12 text-center">
                      <Button
                        variant="text"
                        onClick={() => setShowCard(true)}
                        className="LinkBtn"
                      >
                        <AddIcon />
                        {t("addNewCard")}
                      </Button>
                    </div>
                  </div>
                </div>
                {/*<div className="checkoutBlocks">*/}
                {/*  <h5 className="checkoutBlocks-title">{t("payWithBlance")}:</h5>*/}
                {/*  <p className="text-center">{t("totalEcredits")}:147</p>*/}
                {/*</div>*/}
                {/*<div className="checkoutBlocks">*/}
                {/*  <h5 className="checkoutBlocks-title">{t("payWithBlance")}:</h5>*/}
                {/*  <div className="text-center">*/}
                {/*    <Button variant="text" className="LinkBtn">*/}
                {/*      <AddIcon />*/}
                {/*      {t("showAllBonuses")}*/}
                {/*    </Button>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="checkoutFooterBtn">
                  {isCheckoutPending && <CircularIndeterminate />}
                  <Button variant="outlined" onClick={onCheckout}>
                    {t("payNow")}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                {showaddress ? (
                  <div>
                    <div className="templateHeading">
                      <span className="backIcon">
                        <ArrowBackIcon onClick={() => setShowAddress(false)} />
                      </span>
                      <h1 className="templateTitle"> {t("newAddress")}</h1>
                    </div>
                    <div className="checkoutBlocks">
                      <h5 className="checkoutBlocks-title text-center">
                        {t("deliveryAddress")}
                      </h5>
                      <div className="row">
                        <div className="col-lg-6">
                          <TextField
                            id="outlined-basic"
                            label={t("country")}
                            variant="outlined"
                          />
                        </div>
                        <div className="col-lg-6">
                          <TextField
                            id="outlined-basic"
                            label={t("city")}
                            variant="outlined"
                          />
                        </div>
                        <div className="col-lg-6">
                          <TextField
                            id="outlined-basic"
                            label={t("zipcode")}
                            variant="outlined"
                          />
                        </div>
                        <div className="col-lg-6">
                          <TextField
                            id="outlined-basic"
                            label={t("street")}
                            variant="outlined"
                          />
                        </div>
                        <div className="col-lg-6">
                          <TextField
                            id="outlined-basic"
                            label={t("appartment")}
                            variant="outlined"
                          />
                        </div>
                        <div className="col-lg-6">
                          <TextField
                            id="outlined-basic"
                            label={t("phone")}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="checkoutFooterBtn">
                      <Button
                        variant="outlined"
                        onClick={() => setShowAddress(false)}
                      >
                        {t("save")}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="templateHeading">
                      <span className="backIcon">
                        <ArrowBackIcon onClick={() => setShowCard(false)} />
                      </span>
                      <h1 className="templateTitle">{t("addNewCard")}</h1>
                    </div>
                    <Box
                      component="form"
                      className="checkoutBlocks"
                      onSubmit={handleSubmit}
                    >
                      <div className="checkoutBlocks-mainImg text-center">
                        <img
                          loading="lazy"
                          src={Debitcard}
                          className="img-fluid"
                          alt="card"
                        />
                      </div>
                      <h5 className="checkoutBlocks-title text-center">
                        {t("paymentMethod")}
                      </h5>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="cardTextNo">
                            <FormControl>
                              <TextField
                                required
                                // error={submit && !card?.number}
                                id="outlined-basic"
                                label={t("cardNumber")}
                                variant="outlined"
                                onChange={handleChangeCardNumber}
                                value={cardNumberMask(card.number)}
                              />
                            </FormControl>
                            {/*<img loading="lazy"*/}
                            {/*  src={MasterCard}*/}
                            {/*  alt=""*/}
                            {/*  height={100}*/}
                            {/*  width={100}*/}
                            {/*  className="img-fluid"*/}
                            {/*/>*/}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row">
                            <div className="col-6">
                              <FormControl>
                                <TextField
                                  required
                                  id="outlined-basic"
                                  label={t("expiryDate")}
                                  variant="outlined"
                                  value={cardDateMask(card.expirationDate)}
                                  onChange={handleChangeExpiryDate}
                                />
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={['DateField']}
                                    sx={{ p: 0 }}
                                  >
                                    <DateField
                                      required
                                      id="outlined-basic"
                                      sx={{ minWidth: 0 }}
                                      label={t("expiryDate")}
                                      variant="outlined"
                                      format="MM/YY"
                                      onChange={(newValue) => {
                                        console.log(newValue.$d.getFullYear(), newValue.$d.getMonth());
                                        setCard({
                                          ...card,
                                          expirationDate: newValue,
                                        })
                                      }}
                                      minDate={today}
                                      value={card?.expirationDate}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider> */}
                              </FormControl>
                            </div>
                            <div className="col-6">
                              <FormControl>
                                <TextField
                                  required
                                  // error={submit && !card?.cvv2}
                                  id="outlined-basic"
                                  label={t("CVC/CVV")}
                                  variant="outlined"
                                  onChange={handleChangeCVC}
                                  value={card.cvv2 || ""}
                                />
                              </FormControl>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <FormControl>
                            <TextField
                              required
                              // error={submit && !card?.name}
                              id="outlined-basic"
                              label={t("cardName")}
                              variant="outlined"
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  name: e.currentTarget.value,
                                })
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="col-lg-12">
                          <FormControl>
                            <TextField
                              required
                              // error={submit && !card?.idCard}
                              id="outlined-basic"
                              label={t("id")}
                              variant="outlined"
                              onChange={(e) =>
                                setCard({
                                  ...card,
                                  idCard: e.currentTarget.value,
                                })
                              }
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="checkoutFooterBtn">
                        <Button
                          variant="outlined"
                          type="submit"
                          // onClick={handleSubmit}
                        >
                          {t("save")}
                        </Button>
                      </div>
                    </Box>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
