export const OldRouteMap = {
  "burger-2": 7287,
  "one-night": 7293,
  "two-night": 7292,
  asia: 7261,
  boker: 7260,
  "boker-kosher": 7259,
  6: 7255,
  7: 7258,
  breakfast1: 7272,
  2: 7254,
  "platinum-global": 7274,
  "platinum-shopping": 7277,
  11: 7286,
  13: 7284,
  10: 7285,
  12: 7283,
  3: 7256,
  4: 7257,
  9: 7265,
  8: 7264,
  vip2: 7267,
  vip1: 7266,
  spa4: 7270,
  spa3: 7271,
  spa2: 7269,
  spa1: 7268,
  pizza: 7280,
  "pizza-plus": 7281,
  "pizza-side": 7289,
  "platinum-hotels-bb": 7278,
  "platinum-hotels-hb": 7279,
  "platinum-chef": 7273,
  "platinum-spa3": 7291,
  "platinum-spa1": 7275,
  "platinum-spa4": 7290,
  "platinum-spa2": 7276,
  coffee2: 7263,
  coffee1: 7262,
  5: 7277,
  "2pizza": 7282,
  "pizza-drink": 7288,
};
