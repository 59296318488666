import React, { useState, useEffect } from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import PersonOutline from "@material-ui/icons/PersonOutline";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import MenuIcon from "@mui/icons-material/Menu";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import MicIcon from "@mui/icons-material/Mic";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import {
  setGetTabbingValue,
  openSidebar,
  setB2BValue,
  getUnused,
} from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";
import "./header.css";
import Logo from "../../assets/images/logo.png";
import SearchResult from "../searchResult/SearchResult";
const Header = ({ searchText, setSearchText }) => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false); // eslint-disable-line
  const [subToggleMenu, setSubToggleMenu] = useState(false);
  const [notification, setNotification] = useState(false);
  const { tabbingValue } = useSelector((state) => state.tabbing);
  const [value, setValue] = useState(tabbingValue);
  const [isPageWithBusinessToggle, setIsPageWithBusinessToggle] =
    useState(false);
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  // const [searchText, setSearchText] = useState([]); // eslint-disable-line
  const [minPrice, setMinPrice] = useState(0); // eslint-disable-line
  const [maxPrice, setMaxPrice] = useState(0); // eslint-disable-line
  const [filterProducts, setFilterProducts] = useState([]); // eslint-disable-line
  const [filterStores, setFilterStores] = useState([]); // eslint-disable-line
  const unused = useSelector((state) => state.order?.unused);

  const productCategories = useSelector(
    (state) => state.productCategories.originalProductCategories
  );
  const merchants = useSelector((state) => state.merchant.merchants);
  const b2b = useSelector((state) => state.tabbing.b2b);
  useEffect(() => {
    !unused.length && dispatch(getUnused());
  }, []);
  useEffect(() => {
    const pathname = location.pathname;
    setIsPageWithBusinessToggle(
      pathname.includes("/club") ||
        pathname.includes("/checkout") ||
        pathname.includes("/payment") ||
        pathname.includes("/loadmoney") ||
        pathname.includes("/paymenthistory") ||
        pathname.includes("/checkout/pay") ||
        pathname.includes("/paymentcardhistory") ||
        pathname.includes("/cart") ||
        pathname.includes("/wallet") ||
        pathname.includes("/bookingcart") ||
        pathname.includes("/bookingcartdetail") ||
        pathname.includes("/bookingpayment") ||
        pathname.includes("/payments") ||
        pathname.includes("/prices")
    );
  }, [location]);

  //  search
  // useEffect(() => {
  //   if (productCategories.length) {
  //     const prepareProduct = productCategories.reduce(
  //       (previous, current) => [
  //         ...previous,
  //         ...current.products.map((product) => ({
  //           ...product,
  //           categoryId: current.id,
  //           categoryName: current.title,
  //         })),
  //       ],
  //       []
  //     );
  //     setProducts(prepareProduct);
  //     setStores(merchants);
  //   }
  // }, [productCategories]); // eslint-disable-line

  // useEffect(() => {
  //   if (products.length) {
  //     const prices = [];
  //     products.forEach((product) => {
  //       if (product?.price) {
  //         prices.push(parseFloat(product.price));
  //       }
  //     });

  //     if (prices.length) {
  //       prices.sort((a, b) => a - b);
  //       setMinPrice(prices[0]);
  //       setMaxPrice(prices[prices.length - 1]);
  //     }
  //   }
  // }, [products]);

  // useEffect(() => {
  //   const filteredP = products.filter((product) =>
  //     product.title.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   filteredP === products
  //     ? setFilterProducts([])
  //     : setFilterProducts(filteredP);

  //   const filteredS = stores.filter((store) =>
  //     store.title.toLowerCase().includes(searchText.toLowerCase())
  //   );

  //   filteredS === stores ? setFilterStores([]) : setFilterStores(filteredS);
  // }, [searchText]); // eslint-disable-line

  const cartCount = useSelector((state) => state.cart.count);
  const user = useSelector((state) => state?.user?.user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setGetTabbingValue(newValue));

    // if (newValue === 0) {
    //   dispatch(setGetTabbingValue(newValue));
    //   // navigate("/");
    // }
    // if (newValue === 1) {
    //   // navigate("/allvendors");
    //   dispatch(setGetTabbingValue(newValue));
    // }
    // if (newValue === 2) {
    //   dispatch(setGetTabbingValue(newValue));
    //   // navigate("/categorylist");
    // }
  };
  const openSearch = () => {
    document.querySelector(".menuSearch").style.display = "none"
      ? "block"
      : "none";
  };
  const handlenotification = () => {
    if (!notification) {
      setNotification(true);
    } else {
      setNotification(false);
    }
  };
  const handleB2B = (event, newValue) => {
    newValue !== null && dispatch(setB2BValue(newValue));
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/search");
    }
  };
  return (
    <div
      className="main "
      // id="menu"
    >
      <div className={isMenuOpen ? "mainheader active-menu" : "mainheader"}>
        <div className="container-fluid">
          <div
            className="row align-items-center"
            style={{ justifyContent: "space-between" }}
          >
            {/* <div className="col-lg-3 col-2"> */}
            <div className="">
              <div className="headerLeft">
                <span
                  className="userBlock-img"
                  onClick={() => {
                    setValue(0);
                    dispatch(setGetTabbingValue(0));
                    navigate("/");
                  }}
                >
                  <img
                    loading="lazy"
                    src={Logo}
                    alt="img"
                    className="img-fluid"
                  />
                </span>
                <Box sx={{ display: { xs: "flex", sm: "none" } }}>
                  <ToggleButtonGroup
                    size="small"
                    value={b2b}
                    exclusive
                    onChange={handleB2B}
                    sx={{
                      direction: "ltr",
                      width: "80px",
                      "&>*": { lineHeight: "12px" },
                    }}
                  >
                    <ToggleButton value={false}>לקוח פרטי</ToggleButton>
                    <ToggleButton value={true}>לקוח עסקי</ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                <div className="MainMenu">
                  {!isPageWithBusinessToggle && (
                    <ToggleButtonGroup
                      size="small"
                      value={b2b}
                      exclusive
                      onChange={handleB2B}
                      sx={{
                        direction: "ltr",
                        mr: 2,
                        "&>*": {
                          lineHeight: "13px",
                          minWidth: "80px",
                        },
                      }}
                    >
                      <ToggleButton value={false}>לקוח פרטי</ToggleButton>
                      <ToggleButton value={true}>לקוח עסקי</ToggleButton>
                    </ToggleButtonGroup>
                  )}

                  <Button
                    sx={{
                      borderColor: "var(--primary)",
                      color: "var(--primary)",
                      m: "0 20px 0 2px ",
                      lineHeight: "13px",
                      minWidth: "120px",
                    }}
                    variant="outlined"
                    onClick={() => window.open("https://sub.raayonit.co.il/")}
                  >
                    שוברי נייר
                  </Button>
                  <Button
                    sx={{
                      borderColor: "var(--primary)",
                      color: "var(--primary)",
                      m: "0 2px",
                      lineHeight: "13px",
                      minWidth: "120px",
                    }}
                    variant="outlined"
                    onClick={() =>
                      window.open("https://cards.raayonit.co.il/extendVoucher")
                    }
                  >
                    הארכת תוקף
                  </Button>
                  <Button
                    sx={{
                      borderColor: "var(--primary)",
                      color: "var(--primary)",
                      m: "0 2px",
                      lineHeight: "13px",
                      minWidth: "120px",
                    }}
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        "https://sub.raayonit.co.il/Site/he/pages/inPage.asp?page=giftcard&action=checkbudget"
                      )
                    }
                  >
                    בירור יתרה
                  </Button>
                  <Button
                    sx={{
                      background: "var(--primary)",
                      color: "white",
                      m: "0 2px",
                      lineHeight: "13px",
                      minWidth: "120px",
                      "&:hover": {
                        opacity: "0.5",
                        background: "var(--primary)",
                      },
                    }}
                    variant="outlined"
                    onClick={() => navigate("/allvendors")}
                  >
                    הכוכבים שלנו
                  </Button>
                </div>
              </div>
            </div>

            <div className="text-right" style={{ display: "flex" }}>
              <form
                onSubmit={(e) => e.preventDefault()}
                className={
                  isSearchOpen
                    ? "search-container d-none d-lg-block active-search"
                    : "search-container d-none d-lg-block"
                }
              >
                <div className="search-container__btn">
                  <SearchOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("search")}
                  />
                </div>
                <input
                  type="text"
                  id="search-bar"
                  placeholder={`${t("Search")}`}
                  className="search-container__input"
                  onChange={(e) => setSearchText(e.target.value)}
                  onClick={openSearch}
                  onKeyDown={handleKeyDown}
                  value={searchText}
                />
                <div className="mic-container__btn">
                  <MicIcon />
                </div>
              </form>
              <SearchResult
                searchText={searchText}
                // filterProducts={filterProducts}
                // filterStores={filterStores}
                // isMap={isMap}
                // mapSearch={mapSearch}
              />
              <div className="headerRight">
                <div
                  className="mainheader__btn mainheader__btn--cart d-flex d-lg-none"
                  onClick={() => {
                    if (subToggleMenu) setSubToggleMenu(false);
                    else setSubToggleMenu(true);
                  }}
                >
                  <span className="openMenu">
                    <MenuIcon />
                  </span>
                  <span className="closeMenu">
                    <CloseOutlinedIcon />
                  </span>
                </div>
                {user && (
                  <>
                    <Button className="dropBtn">
                      <PersonOutline
                        onClick={() =>
                          widgetHelper.openProfile(() =>
                            dispatch(openSidebar(), () =>
                              navigate("/userprofile")
                            )
                          )
                        }
                      />
                    </Button>
                    <div className="notificationBlock">
                      <Button className="dropBtn" onClick={handlenotification}>
                        <NotificationsNoneIcon />
                        <span className="subCount ">1</span>
                      </Button>
                      {notification ? (
                        <div className="notificationList">
                          <div className="chatMainBox">
                            <div className="chatMainBox__inner">
                              <div className="chatMainBox__img">
                                <img
                                  loading="lazy"
                                  src={Logo}
                                  alt=""
                                  height={50}
                                  width={50}
                                  className="img-fluid"
                                />
                              </div>
                              <div className="chatMainBox__info">
                                <h4 className="chatMainBox__name">
                                  {t("chatbot")}
                                </h4>
                                <p className="chatMainBox__text">
                                  ברוכים הבאים לרעיונית! מועדון ההטבות המוביל
                                  בישראל
                                </p>
                              </div>
                            </div>
                            <span className="chatMainBox__time">4pm</span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}

                {!user && (
                  <Button
                    onClick={() =>
                      widgetHelper.login(
                        () => dispatch(openSidebar())
                        // () => navigate("/login")
                      )
                    }
                    className="loginBtn d-lg-block "
                  >
                    {!user ? t("login") : t("sign out")}
                  </Button>
                )}

                <div
                  className="mainheader__btn mainheader__btn--cart"
                  onClick={() => {
                    if (
                      window.location.pathname === "/company" ||
                      window.location.pathname === "/product" ||
                      window.location.pathname === "/bookingcart" ||
                      window.location.pathname === "/bookingcartdetail"
                    )
                      navigate("/bookingcart");
                    else {
                      if (!user) {
                        widgetHelper.login(
                          () => dispatch(openSidebar()),
                          () => navigate("/cart")
                        );
                      } else {
                        navigate("/cart");
                      }
                    }
                  }}
                >
                  <ShoppingCartOutlinedIcon />
                  <span className="subCount subCountRight">
                    {cartCount || 0}
                  </span>
                </div>
                {/* <div className="mainheader__btn mainheader__btn--cart d-flex d-lg-none">
                  <AddLocationAltIcon
                    onClick={() => {
                      navigate("/map");
                    }}
                  />
                </div> */}
                <div className="mainheader__btn mainheader__btn--cart">
                  <AccountBalanceWalletIcon
                    onClick={() => {
                      if (!user) {
                        widgetHelper.login(
                          () => dispatch(openSidebar()),
                          () => navigate("/login")
                        );
                      } else {
                        navigate("/wallet");
                      }
                    }}
                  />
                  <span className="subCount subCountRight">
                    {unused?.length || 0}
                  </span>
                </div>
                {/* <div className="mainheader__btn mainheader__btn--cart d-none d-lg-flex">
                  <AddLocationAltIcon
                    onClick={() => {
                      navigate("/map");
                    }}
                  />
                </div> */}

                {/* <div className="lanSelector">
                  <LanguageSelect />
                </div> */}
              </div>
            </div>
            {/* Mobile */}
            <div className="col-12 d-flex d-lg-none">
              <div
                className={
                  subToggleMenu ? "mobileSearch activeSubMenu" : "mobileSearch "
                }
              >
                <div className="responsiveSubMenu">
                  <div className="hiddenBtns">
                    {/* <ToggleButtonGroup
                      size="small"
                      value={b2b}
                      exclusive
                      onChange={handleB2B}
                      sx={{ direction: "ltr" }}
                    >
                      <ToggleButton value={false}>לקוח פרטי</ToggleButton>
                      <ToggleButton value={true}>לקוח עסקי</ToggleButton>
                    </ToggleButtonGroup> */}
                    <Button
                      sx={{
                        borderColor: "var(--primary)",
                        color: "var(--primary)",
                        m: "0 20px 0 2px ",
                      }}
                      variant="outlined"
                      onClick={() => window.open("https://sub.raayonit.co.il/")}
                    >
                      שוברי נייר
                    </Button>
                    <Button
                      sx={{
                        borderColor: "var(--primary)",
                        color: "var(--primary)",
                        m: "0 20px 0 2px ",
                      }}
                      variant="outlined"
                      onClick={() =>
                        window.open(
                          "https://cards.raayonit.co.il/extendVoucher"
                        )
                      }
                    >
                      הארכת תוקף
                    </Button>
                    <Button
                      sx={{
                        background: "var(--primary)",
                        color: "white",
                        m: "0 20px 0 2px ",
                        lineHeight: "13px",
                        "&:hover": {
                          opacity: "0.5",
                          background: "var(--primary)",
                        },
                      }}
                      variant="outlined"
                      onClick={() => navigate("/allvendors")}
                    >
                      הכוכבים שלנו
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* Mobile */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
