import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Location } from "../../assets/icons/locate.svg";
import {
  Button,
  Link,
  Slider,
  Box,
  Dialog,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@mui/material";
import Categories from "../dashboard/Categories";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  getProducts,
  getMerchants,
  getRegions,
  getProductCategories,
} from "../../redux/actions-exporter";
import "../vendor/vendorsDetails.css";
import BasicPagination from "../dashboard/BasicPagination";

const marks = [
  {
    value: 0,
    label: '1 ק"מ',
  },
  {
    value: 1,
    label: '5 ק"מ',
  },
  {
    value: 2,
    label: '10 ק"מ',
  },
  {
    value: 3,
    label: '50 ק"מ',
  },
  {
    value: 4,
    label: "הכל",
  },
];
const radii = [1000, 5000, 10000, 50000, null];
const ITEMS_PER_PAGE = 20;

export default function AllVendor({ dis, setDis }) {
  const regions = useSelector((state) => state.region?.regions);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dbMerchants = useSelector((state) => state.merchant.merchants);
  const merchants = useMemo(
    () => dbMerchants.filter((merchant) => merchant.active === true),
    [dbMerchants]
  );
  const { isMerchantsPending } = useSelector((state) => state.merchant);
  const [filterMerchants, setFilterMerchants] = useState([]);
  const [currentPageMerchants, setCurrentPageMerchants] = useState([]);
  const [detailsPop, setDetailsPop] = useState(false);
  const [branchesPop, setBranchesPop] = useState(false);
  const [branches, setBranches] = useState([]);
  const [geoState, setGeoState] = useState(undefined);
  const [details, setDetails] = useState();
  const [geoPermission, setGeoPermission] = useState(null);
  const [currentCategory, setCurrentCategory] = useState("all");
  const [pageNumber, setPageNumber] = useState(1);
  const products = useSelector((state) => state.product.products);
  const categories = useSelector(
    (state) => state.productCategories.productCategories
  );
  const geo = useSelector((state) => state.user.geo);

  const getGeoPermission = useCallback(() => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        setGeoPermission(permissionStatus.state);
      });
  }, []);

  useEffect(() => {
    getGeoPermission();
    if (!categories || !categories.length) {
      dispatch(getProductCategories());
    }
  }, [dispatch, navigator.permissions]);

  const handleChangeCategory = (catId) => {
    let newM = [];
    setPageNumber(1);
    setCurrentCategory(catId);
    if (catId === "all") {
      newM = [...merchants];
    } else {
      merchants.forEach((merchant) => {
        merchant.productsCategories.forEach((category) => {
          if (category.id === catId) newM.push(merchant);
        });
      });
    }
    const uniques = Array.from(new Set(newM.map((a) => a.id))).map((id) => {
      return newM.find((a) => a.id === id);
    });
    let groups = Object.values(
      uniques.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  };

  useEffect(() => {
    if (!products.length) dispatch(getProducts());
    if (!merchants.length || geoState !== geo?.state) {
      if (geo?.state === "denied" || geo?.state === "granted") {
        const radius = radii[dis];
        dispatch(
          getMerchants(
            radius ? geo.longitude : undefined,
            radius ? geo.latitude : undefined,
            null,
            0,
            0,
            undefined,
            "merchant"
          )
        );
      }
      setGeoState(geo?.state);
    }
    if (!regions?.length) dispatch(getRegions());
  }, [dispatch, geo]);

  useEffect(() => {
    let groups = Object.values(
      merchants.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }

        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  }, [dbMerchants, dis]);

  const handleSearch = (e) => {
    setPageNumber(1);
    let newM = merchants.filter((merchant) => {
      return (
        merchant.title.toLowerCase().includes(e.toLowerCase()) ||
        merchant.network?.name.toLowerCase().includes(e.toLowerCase())
      );
    });
    let groups = Object.values(
      newM.reduce((acc, curr) => {
        const firstWord = curr.network?.name || curr.title;
        if (!acc[firstWord]) {
          acc[firstWord] = [];
        }
        acc[firstWord].push(curr);
        return acc;
      }, {})
    );
    groups = groups.sort((a, b) => b.length - a.length);
    setFilterMerchants(groups);
  };

  const handleChangeRadius = useCallback(
    (e, value) => {
      if (geoPermission === "granted") {
        setPageNumber(1);
        const radius = radii[value];
        dispatch(
          getMerchants(
            radius ? geo.longitude : undefined,
            radius ? geo.latitude : undefined,
            radius,
            0,
            0,
            undefined,
            "merchant"
          )
        );
      }
      setDis(value);
    },
    [geo, geoPermission]
  );

  useEffect(() => {
    if (filterMerchants.length) {
      setCurrentPageMerchants(
        filterMerchants.slice(
          ITEMS_PER_PAGE * (pageNumber - 1),
          ITEMS_PER_PAGE * pageNumber
        )
      );
    } else {
      setCurrentPageMerchants([]);
    }
  }, [pageNumber, filterMerchants]);

  const handlePaginate = (e, page) => setPageNumber(page);

  return (
    <div>
      <div className="pageTemplate">
        <div className="container">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="vendorSearch">
              <h6 className="module-heading__title vendorSearchTitle">
                חיפוש:{" "}
              </h6>
              <TextField
                id="search-bar"
                sx={{ background: "#f9f9f9", ml: 5 }}
                placeholder="בית העסק"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>

            {geo.latitude && (
              <Box
                sx={{
                  width: 350,
                  my: 2,
                  mx: 0,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {geoPermission !== "granted" && radii[dis] && (
                  <span className="module-heading__geoWarning">
                    For filter by location you should be have permission!
                  </span>
                )}
                <h6 className="module-heading__title vendorSearchTitle">
                  מרחק:
                </h6>
                <Slider
                  sx={{
                    color: "var(--primary)",
                    fontFamily: "inherit !important",
                    ml: 2,
                  }}
                  onChange={handleChangeRadius}
                  min={0}
                  max={4}
                  value={dis}
                  step={null}
                  marks={marks}
                />
              </Box>
            )}
          </Box>
          <Categories
            categories={categories}
            handleChange={handleChangeCategory}
            currentCategory={currentCategory}
          />
          <div className="vendorAllListBlock">
            <h6 className="module-heading__title">{t("allvendors")}</h6>
            {isMerchantsPending && (
              <>
                <div className="circularProgress-background"></div>
                <CircularProgress className="circularProgress" />
              </>
            )}
            <ul className="store-Module">
              {currentPageMerchants?.length > 0
                ? currentPageMerchants.map((store) => (
                    <>
                      <li className="store-Module__list isLink">
                        <div className="store-box">
                          {store[0].distance && store.length === 1 && (
                            <div className="category-boxSubTitle">
                              <Location fill={"var(--primary)"} />
                              {store[0].distance}
                              <br />
                              ק"מ
                            </div>
                          )}
                          {store.length == 1 &&
                            store[0].kosher &&
                            store[0].kosher != "לא כשר" && (
                              <div className="product-box__discount">
                                <span className="product-box__off">
                                  {store[0].kosher || ""}
                                </span>
                              </div>
                            )}
                          <img
                            loading="lazy"
                            alt=""
                            className="store-box__img"
                            src={store[0].bannerImage || ""}
                            onClick={() => {
                              if (store.length === 1) {
                                navigate(`/vendor/${store[0].id}`, {
                                  state: { id: store[0].id },
                                });
                              }
                            }}
                          />
                          <div
                            className="store-box__logo"
                            style={{
                              backgroundImage: `url(${store[0]?.image})`,
                            }}
                          ></div>
                          <div className="store-box__info">
                            <h6 className="store-box__brand">
                              {store.length === 1
                                ? store[0].title
                                : store[0].network?.name}
                            </h6>

                            <div className="address">
                              {store.length === 1 && store[0]?.address
                                ? store[0].address
                                : ""}
                            </div>
                            <div className="phone">
                              {(store.length === 1 && store[0].telephone) || ""}
                            </div>
                          </div>

                          <div className="store-box__infoTop">
                            {store.length > 1 && (
                              <Button
                                sx={{
                                  borderColor: "var(--primary)",
                                  color: "var(--primary)",
                                }}
                                variant="outlined"
                                onClick={() => {
                                  setBranches(store);
                                  setBranchesPop(true);
                                }}
                                fullWidth
                              >
                                סניפים
                              </Button>
                            )}
                            <Button
                              sx={{
                                borderColor: "var(--primary)",
                                color: "var(--primary)",
                              }}
                              variant="outlined"
                              onClick={() => {
                                setDetails(store[0]);
                                setDetailsPop(true);
                              }}
                              fullWidth
                            >
                              לפרטים נוספים
                            </Button>
                            {store[0].url && (
                              <Link
                                href={
                                  (store[0].url.includes("http")
                                    ? ""
                                    : "https://") + store[0].url
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Button
                                  sx={{
                                    borderColor: "var(--primary)",
                                    color: "var(--primary)",
                                  }}
                                  variant="outlined"
                                  fullWidth
                                >
                                  לאתר
                                </Button>
                              </Link>
                            )}
                          </div>
                        </div>
                      </li>
                    </>
                  ))
                : "בטעינה..."}
            </ul>
          </div>
          <div className="pagination-container">
            <BasicPagination
              productsPerPage={ITEMS_PER_PAGE}
              totalProducts={filterMerchants.length}
              paginate={handlePaginate}
              page={pageNumber}
            />
          </div>
        </div>
      </div>

      <Dialog
        onClose={() => setDetailsPop(false)}
        open={detailsPop}
        sx={{ textAlign: "right" }}
      >
        <DialogTitle>
          <img
            loading="lazy"
            alt=""
            src={details?.image}
            style={{ width: "30%", marginLeft: "10px" }}
          />
          {details?.network ? details?.network?.name : details?.title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setDetailsPop(false)}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            zIndex: "100000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ padding: "0 24px 16px 24px" }}>
          <h6
            dangerouslySetInnerHTML={{ __html: details?.content || "" }}
            style={{ textAlign: "justify" }}
          ></h6>
        </div>
      </Dialog>

      <Dialog
        onClose={() => setBranchesPop(false)}
        open={branchesPop}
        className="branchesModal"
        sx={{
          textAlign: "right",
          overflowY: "hidden !important",
        }}
      >
        <DialogTitle>
          {branches[0]?.network?.name} {"סניפים"}
          <IconButton
            aria-label="close"
            onClick={() => setBranchesPop(false)}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              zIndex: "100000",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ul className="store-Module" style={{ padding: "2%" }}>
          {branches
            .sort((a, b) => b.latitude - a.latitude)
            .map((store) => (
              <li className="store-Module__list isLink">
                <div
                  className="store-box"
                  style={{ height: "150px !important" }}
                >
                  {store.distance && (
                    <div className="category-boxSubTitle">
                      <Location fill={"var(--primary)"} />
                      {store.distance}
                      <br />
                      ק"מ
                    </div>
                  )}
                  {(store?.kosher == "כשר" ||
                    store?.kosher == "כשר למהדרין") && (
                    <div className="product-box__discount">
                      <span className="product-box__off">
                        {store?.kosher || ""}
                      </span>
                    </div>
                  )}
                  <img
                    loading="lazy"
                    alt=""
                    className="store-box__img"
                    src={store.bannerImage || ""}
                    onClick={() => {
                      navigate(`/vendor/${store.id}`, {
                        state: { id: store.id },
                      });
                    }}
                  />
                  <div
                    className="store-box__logo"
                    style={{ backgroundImage: `url(${store.image})` }}
                  ></div>
                  <div className="store-box__info">
                    <h6 className="store-box__brand">{store.title}</h6>

                    <div className="address">
                      {store?.address ? store.address : ""}
                    </div>
                    <div className="phone">{store.telephone || ""}</div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </Dialog>
    </div>
  );
}
