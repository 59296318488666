import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
  getMerchantById,
  getMerchantProducts,
} from "../../redux/API/merchant/merchant.action";
import Send from "../product/Send";
import { openSidebar } from "../../redux/actions-exporter";
import Art from "../../assets/images/art.jpg";
import "../vendor/vendorsDetails.css";
import widgetHelper from "../../helpers/widget";
import ProductCard from "../dashboard/ProductCard";

const s = {
  dialogContainer: {
    textAlign: "right",
    width: "400px",
    m: "auto",
  },
  dialogTitle: {
    padding: "16px 50px",
  },
  dialogIconButton: {
    position: "absolute",
    left: 8,
    top: 8,
    zIndex: "10000",
  },
};

export default function VendorsDetails({ isClub }) {
  const cart = useSelector((state) => state.cart.cart);
  const [inCart, setInCart] = useState(false);
  const [cartPop, setCartPop] = useState(false);
  const [fors, setFors] = useState([]);
  const [zero, setZero] = useState(false);
  const [tooLow, setTooLow] = useState(null);
  const [tooHigh, setTooHigh] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [filterProducts, setFilterProducts] = useState([]);
  const [chosen, setChosen] = useState({ product: {}, price: "", quantity: 1 });
  const { t } = useTranslation();
  // const { id } = location?.state;
  const { id } = useParams();
  const merchant = useSelector((state) => state.merchant.merchant);
  const products = useSelector((state) => state.merchant.merchantProducts);
  const user = useSelector((state) => state?.user?.user);
  const b2b = useSelector((state) => state.tabbing.b2b);
  const [disableBuy, setDisableBuy] = useState();

  const product = location?.state?.product || null;
  let amount = [];
  for (let i = 1; i < 100; i++) amount.push(i);
  useEffect(() => {
    product &&
      setDisableBuy(b2b && product.priceB2B > 0 && product.price === 0);
  }, [b2b]);
  useEffect(() => {
    dispatch(getMerchantById(id));
    dispatch(getMerchantProducts(id));
  }, [id]); //eslint-disable-line

  useEffect(() => {
    setFilterProducts(
      !b2b
        ? products.filter(
            (product) => product.priceB2B === 0 || product.price > 0
          )
        : products
    );
  }, [b2b, products]);

  const handleSubmit = (product) => {
    const { minPrice, maxPrice } = product;
    if (cart?.items?.some((a) => a.product.id === product.id)) {
      setInCart(true);
    } else {
      if (
        product.priceB2B > 0 ||
        (!minPrice && !maxPrice) ||
        (!maxPrice && minPrice && chosen.price >= minPrice) ||
        (!minPrice && maxPrice && chosen.price <= maxPrice) ||
        (chosen.price >= minPrice && chosen.price <= maxPrice)
      ) {
        setCartPop(true);
        setChosen({
          ...chosen,
          product: product,
        });
      } else {
        if (!chosen?.price) {
          setZero(true);
        } else {
          chosen.price >= minPrice ? setTooHigh(maxPrice) : setTooLow(minPrice);
        }
      }
    }
  };

  const buttonOnClick = (product) => {
    // window.alert(
    //   "זמנית לא ניתן לבצע רכישה אונליין אלא טלפונית בלבד: 039223392"
    // );
    if (!user) {
      widgetHelper.login(() => {
        dispatch(openSidebar());
      });
    } else {
      chosen?.product?.id !== product.id &&
        setChosen({
          ...chosen,
          product: product,
          quantity: 1,
        });
      handleSubmit(product);
    }
  };
  return (
    <>
      {/* <Header /> */}
      <div className="container">
        <div className="vendorTemplate">
          <div
            className="vendorbanner"
            style={{
              backgroundImage: `url("${merchant?.bannerImage || Art}")`,
            }}
          >
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="vendorbanner-brand">
                  <span className="vendorbanner-brandImg">
                    <img
                      loading="lazy"
                      src={merchant?.image}
                      className="img-fluid"
                      alt=""
                    />
                  </span>
                  <div className="vendorbanner-brandInfo">
                    <p className="vendorbanner-brandSubtitle">
                      {t(merchant?.title)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", margin: "20px 0" }}>
            <div className="vendorContent">
              {merchant.content || merchant.description ? (
                <>
                  <h6 className="vendorContent-title">{t("description")}</h6>
                  <div
                    className="vendorContent-text"
                    dangerouslySetInnerHTML={{
                      __html: merchant?.content || "",
                    }}
                  ></div>
                </>
              ) : (
                ""
              )}
            </div>
            {product && (
              <div style={{ margin: "auto", cursor: "pointer" }}>
                <ProductCard
                  product={product}
                  b2b={b2b}
                  buttonOnClick={buttonOnClick}
                  chosen={chosen}
                  setChosen={setChosen}
                  disableCategory
                />
              </div>
            )}
          </div>

          <div>
            <div className="productDetails-content module-heading__link">
              <h6 className="productDetails-contentTitle">תוים מכובדים</h6>
            </div>
          </div>
          <ul className="product-Module">
            {filterProducts.length > 0
              ? filterProducts
                  .filter((item) => item.title !== product?.title)
                  .map((product) => (
                    <ProductCard
                      product={product}
                      b2b={b2b}
                      buttonOnClick={buttonOnClick}
                      chosen={chosen}
                      setChosen={setChosen}
                      key={product.id}
                    />
                  ))
              : t("Loading, please wait...")}
          </ul>
        </div>

        <Dialog
          sx={s.dialogContainer}
          onClose={() => setInCart(false)}
          open={inCart}
        >
          <DialogTitle>
            הפריט כבר נמצא בעגלה {""}
            <Button
              onClick={() => navigate("/cart")}
              variant="contained"
              sx={{ mr: 3 }}
            >
              לעגלה
            </Button>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setInCart(false)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog
          sx={s.dialogContainer}
          onClose={() => setTooLow(null)}
          open={Boolean(tooLow)}
        >
          <DialogTitle sx={s.dialogTitle}>
            סכום מינימלי: {tooLow} ₪{" "}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setTooLow(null)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog
          sx={s.dialogContainer}
          onClose={() => setTooHigh(null)}
          open={Boolean(tooHigh)}
        >
          <DialogTitle sx={s.dialogTitle}>
            סכום מקסימלי: {tooHigh} ₪{" "}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setTooHigh(null)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog
          sx={s.dialogContainer}
          onClose={() => setZero(false)}
          open={zero}
        >
          <DialogTitle sx={s.dialogTitle}>נא להכניס סכום</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setZero(false)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>

        <Dialog
          onClose={() => setCartPop(false)}
          open={cartPop}
          maxWidth="xl"
          fullWidth
          sx={{ textAlign: "center" }}
        >
          <DialogTitle></DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setCartPop(false)}
            sx={s.dialogIconButton}
          >
            <CloseIcon />
          </IconButton>
          <Send
            setChosen={setChosen}
            chosen={chosen}
            fors={fors}
            setFors={setFors}
          />
        </Dialog>
      </div>
    </>
  );
}
