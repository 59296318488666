import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  IconButton,
  TextField,
  Grid,
  Box,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import "dayjs/locale/en-gb";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";

import { oneInClickCheckout } from "../../redux/API/cart/cart.action";

const theme = createTheme({
  direction: "rtl",
});
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const BuyInOneClick = ({ setBuyInOneClick, buyInOneClick, chosen }) => {
  const [state, setState] = useState({});
  const dispatch = useDispatch();
  const { isOneClickCheckoutPending, oneClickCheckout } = useSelector(
    (state) => state.cart
  );
  const { product, quantity } = chosen;

  const onClick = () => {
    // TODO: блокировать кнопку во время отправки
    dispatch(oneInClickCheckout({ ...state, productId: product.id }));
  };

  useEffect(() => {
    console.log(oneClickCheckout);
  }, [oneClickCheckout]);

  return (
    <Dialog
      onClose={() => setBuyInOneClick(false)}
      open={buyInOneClick}
      maxWidth="sm"
      fullWidth
      sx={{ textAlign: "center" }}
    >
      <DialogTitle>קנה בלחיצה אחת</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setBuyInOneClick(false)}
        sx={{
          position: "absolute",
          left: 8,
          top: 8,
          zIndex: "10000",
        }}
      >
        <CloseIcon />
      </IconButton>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <Container component="main">
            <Box component="form" noValidate sx={{ mt: 3 }}>
              {oneClickCheckout?.paymentURL ? (
                <iframe
                  title="payment"
                  className="checkout__payment"
                  src={oneClickCheckout.paymentURL}
                />
              ) : (
                <>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <img
                        alt=""
                        title=""
                        src={product.image}
                        loading="lazy"
                        height={200}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <FormControlLabel
                        value={true}
                        control={<FormLabel>{product.title}</FormLabel>}
                        label=":שם"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <FormControlLabel
                        value={true}
                        control={<FormLabel>{quantity}</FormLabel>}
                        label=":כמות"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        name="name"
                        id="name"
                        size="small"
                        label="שם"
                        fullWidth
                        onChange={(e) =>
                          setState({ ...state, name: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        name="phone"
                        id="phone"
                        size="small"
                        label="מספר נייד"
                        fullWidth
                        onChange={(e) =>
                          setState({ ...state, phone: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        name="email"
                        id="email"
                        size="small"
                        label="דואר"
                        fullWidth
                        onChange={(e) =>
                          setState({ ...state, email: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                  <Button
                    onClick={onClick}
                    variant="contained"
                    disabled={isOneClickCheckoutPending}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    לִקְנוֹת
                  </Button>
                </>
              )}
            </Box>
          </Container>
        </ThemeProvider>
      </CacheProvider>
    </Dialog>
  );
};

export default BuyInOneClick;
