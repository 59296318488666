import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button } from "@material-ui/core";

import Header from "../header/Header";
import {
  getOrders,
  getUsage,
  getUnused,
  getBalance,
} from "../../redux/actions-exporter";

import "../wallets/wallets.css";

function Wallets() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [code, setCode] = useState(false);

  const user = useSelector((state) => state.user.user);
  const balance = useSelector((state) => state.order.balance);
  const used = useSelector((state) => state.order?.usage);
  const unused = useSelector((state) => state.order?.unused);

  useEffect(() => {
    // dispatch(getOrders());
    dispatch(getUsage());
    dispatch(getUnused());
  }, []); // eslint-disable-line
  return (
    <div>
      <div className="paymentHestoryTemplate">
        <div className="templateHeading">
          <span
            className="backIcon isLink"
            onClick={() => {
              navigate("/wallet");
            }}
          >
            <ArrowBackIcon />
          </span>
          <h1 className="templateTitle">ארנק</h1>
        </div>
        <div className="three">
          {/* <div className="container">
            <div className="templateHeading">
              <h1 className="templateSubTitle">פינקתי</h1>
            </div>
            <ul className="paymentHestoryCards">
              {unused.length > 0
                ? unused.map((order, i) => (
                  <li className="cartProduct-Module__list" key={i}>
                    <div className="cartProduct-box1">
                      <div className="cartProduct-box__info1">
                        <h6 className="cartProduct-box__brand">
                          {order?.card?.product?.title}
                        </h6>
                        <div
                          className="cartProduct-box__img"
                          style={{
                            backgroundImage: `url(${order?.card?.product?.image})`,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="cartProduct-rightBlock"
                      style={{ minWidth: "0" }}
                    >
                      <div className="cartProduct-box__brandBox">
                        <div
                          className="cartProduct-box__price"
                          style={{
                            display: "block",
                            textAlign: "center",
                          }}
                        >
                          ₪ {order?.amount}
                          <br />
                          את: נועה קירל
                          <br />
                          ב-
                          {moment(order.createdAt)
                            .utcOffset(120)
                            .format("DD/MM/YY")}
                          <div className="cartProduct-box__metadata"></div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
                : "אין שוברים להציג"}
            </ul>
          </div> */}

          <div className="container">
            <div className="templateHeading">
              <h1 className="templateSubTitle">פונקתי</h1>
            </div>
            <ul className="paymentHestoryCards">
              {used.length > 0
                ? used.map((order, i) => (
                    <li className="cartProduct-Module__list" key={i}>
                      <div className="cartProduct-box1">
                        <div className="cartProduct-box__info1">
                          <h6 className="cartProduct-box__brand">
                            {order?.card?.product?.title}
                          </h6>
                          <div
                            className="cartProduct-box__img"
                            style={{
                              backgroundImage: `url(${order?.card?.product?.image})`,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        className="cartProduct-rightBlock"
                        style={{ minWidth: "0" }}
                      >
                        <div className="cartProduct-box__brandBox">
                          <div
                            className="cartProduct-box__price"
                            style={{
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            {order?.amount} ₪
                            <br />
                            ב-
                            {moment(order.createdAt)
                              .utcOffset(120)
                              .format("DD/MM/YY")}
                            <br />
                            {order.supplierName}
                            <div className="cartProduct-box__metadata"></div>
                          </div>
                        </div>
                      </div>
                    </li>
                    // <li className="cartProduct-Module__list" key={i}>
                    //   <div className="cartProduct-box">
                    //     <div className="cartProduct-box__info">
                    //       <h6 className="cartProduct-box__brand">
                    //       </h6>
                    //       <br />

                    //       <h5 className="cartProduct-box__name">
                    //         מומש בתאריך:
                    //       </h5>
                    //       <h4 className="cartProduct-box__name">
                    //         {moment(order.createdAt)
                    //           .utcOffset(120)
                    //           .format("DD/MM/YYYY hh:mm")}
                    //       </h4>
                    //     </div>
                    //   </div>
                    //   <div className="cartProduct-rightBlock"
                    //     style={{ minWidth: '0' }}
                    //   >
                    //     <div className="cartProduct-box__brandBox">
                    //       <div
                    //         className="cartProduct-box__price"
                    //         style={{ display: "block" }}
                    //       >
                    //         {order?.amount
                    //           ? order?.amount
                    //           : order?.order?.total}
                    //         ₪
                    //         <br />
                    //         {order.order ? (
                    //           <div className="cartProduct-box__metadata">
                    //             {order.supplierName}
                    //             <br />
                    //             {moment(order.usedDate)
                    //               .utcOffset(120)
                    //               .format("DD/MM/YYYY hh:mm")}
                    //           </div>
                    //         ) : (
                    //           <div className="cartProduct-box__metadata">
                    //             <Button
                    //               className="addcart_btn"
                    //             >
                    //               <span>הצג</span>
                    //             </Button>

                    //           </div>
                    //         )}
                    //       </div>
                    //     </div>
                    //   </div>
                    // </li>
                  ))
                : "אין שוברים להציג"}
            </ul>
          </div>

          <div className="container">
            <div className="templateHeading">
              <h1 className="templateSubTitle">טרם מימשתי</h1>
            </div>
            <ul className="paymentHestoryCards">
              {unused.length > 0
                ? unused.map((order, i) => (
                    <li
                      className="cartProduct-Module__list"
                      // style={{ justifyContent: 'space-between' }}
                      key={i}
                    >
                      <div className="cartProduct-box1">
                        <div className="cartProduct-box__info1">
                          <h6 className="cartProduct-box__brand">
                            {order?.product?.title}
                          </h6>
                          <div
                            className="cartProduct-box__img"
                            style={{
                              backgroundImage: `url(${order?.product?.image})`,
                            }}
                          ></div>
                        </div>
                      </div>
                      {code === order && (
                        <div className="cartProduct-box1">
                          קוד מולטיפס:
                          <br />
                          {code.cardNumber}-{code.pinCode}
                        </div>
                      )}
                      <div
                        className="cartProduct-rightBlock"
                        style={{ minWidth: "0" }}
                      >
                        <div className="cartProduct-box__brandBox">
                          <div
                            className="cartProduct-box__price"
                            style={{
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            <br />
                            {!order?.active && (
                              <div style={{ color: "red" }}>{t("blocked")}</div>
                            )}
                            {order?.product?.benefitType === "load" ? (
                              <Button
                                disabled={balance.id === order.id}
                                className={`addcart_btn ${
                                  balance.id === order.id && "dis"
                                }`}
                                sx={{ width: "100px" }}
                                onClick={() => dispatch(getBalance(order.id))}
                              >
                                {balance.id === order.id
                                  ? `יתרה: ${
                                      balance?.balance || order?.amount
                                    } ₪`
                                  : "בירור יתרה"}
                              </Button>
                            ) : (
                              ""
                            )}
                            <br />
                            תוקף:{" "}
                            {order.expirationDate ? (
                              <>
                                {moment(order.expirationDate)
                                  .utcOffset(120)
                                  .format("DD/MM/YY")}
                                <br />
                              </>
                            ) : (
                              "אין מידע"
                            )}
                            <div className="cartProduct-box__metadata">
                              {user?.phone.includes(
                                order?.delayedSend?.phone
                              ) ? (
                                <Button
                                  className="addcart_btn"
                                  sx={{ width: "100px" }}
                                  onClick={() =>
                                    setCode(code === order ? false : order)
                                  }
                                >
                                  {code === order ? "הסתרת קוד" : "הצגת קוד"}
                                </Button>
                              ) : (
                                <span>הוענק ל {order?.delayedSend?.name}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                : "אין שוברים להציג"}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wallets;
