import React from "react";
import { useTranslation } from "react-i18next";

import Image from "./assets/images/solucion-problemas.png";

const styles = {
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  message: {
    fontSize: 18,
    fontWeight: 500,
  },
  imageBox: {
    width: 200,
    height: 200,
    margin: 20,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  button: {
    fontSize: 20,
  },
};

export default () => {
  const { t } = useTranslation();
  return (
    <div style={styles.container}>
      <span style={styles.message}>
        {t("An error has occurred, but we are already working on it!")}
      </span>
      <div style={styles.imageBox}>
        <img loading="lazy" style={styles.image} src={Image} alt="error pic" />
      </div>
      <a href="/" style={styles.button}>
        {t("Home")}
      </a>
    </div>
  );
};
