import { CardActionTypes } from "./card.types";
import { axiosInstance } from "../../axios.config";

export const getCards = () => (dispatch) => {
  dispatch({ type: CardActionTypes.GET.CARDS_PENDING });

  axiosInstance
    .get("/card")
    .then((res) => {
      dispatch({
        type: CardActionTypes.GET.CARDS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: CardActionTypes.GET.CARDS_ERROR,
        payload: error.response.data,
      });
    });
};

export const addCard = (data) => (dispatch) => {
  dispatch({ type: CardActionTypes.ADD.CARD_PENDING });

  axiosInstance
    .post("/card", data)
    .then((res) => {
      dispatch({
        type: CardActionTypes.ADD.CARD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: CardActionTypes.ADD.CARD_ERROR,
        payload: error.response.data,
      });
    });
};

export const removeCard = (cardId) => (dispatch) => {
  dispatch({ type: CardActionTypes.DELETE.CARD_PENDING });

  axiosInstance
    .delete(`/card/${cardId}`)
    .then((res) => {
      dispatch({
        type: CardActionTypes.DELETE.CARD_SUCCESS,
        payload: cardId,
      });
    })
    .catch((error) => {
      dispatch({
        type: CardActionTypes.DELETE.CARD_ERROR,
        payload: error.response.data,
      });
    });
};

export const deleteErrors = (dispatch) => {
  dispatch({ type: CardActionTypes.DELETE_ERRORS });
};
