export const CartActionTypes = {
  GET_CART_COUNT_SUCCESS: "GET_CART_COUNT_SUCCESS",
  GET_CART_PENDING: "GET_CART_PENDING",
  GET_CART_SUCCESS: "GET_CART_SUCCESS",
  GET_CART_ERROR: "GET_CART_ERROR",

  POST: {
    CHECKOUT_PENDING: "POST_CHECKOUT_PENDING",
    CHECKOUT_SUCCESS: "POST_CHECKOUT_SUCCESS",
    CHECKOUT_ERROR: "POST_CHECKOUT_ERROR",

    ONE_CLICK_CHECKOUT_PENDING: "POST_ONE_CLICK_CHECKOUT_PENDING",
    ONE_CLICK_CHECKOUT_SUCCESS: "POST_ONE_CLICK_CHECKOUT_SUCCESS",
    ONE_CLICK_CHECKOUT_ERROR: "POST_ONE_CLICK_CHECKOUT_ERROR",
  },
};
