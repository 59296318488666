import React, { Component } from "react";
import { format } from "date-fns";

import CrashPage from "../Crash";
import { sendCrash } from "../../redux/API/crash/crash.action";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    const user = {
      id: null,
      email: null,
      firstName: "unauthorized",
      lastName: "unauthorized",
      createdAt: null,
    };

    if (error) {
      if (this.props.user) {
        user.id = this.props.user.id;
        user.email = this.props.user.email;
        user.firstName = this.props.user.firstName;
        user.lastName = this.props.user.lastName;
        user.createdAt = this.props.user.createdAt;
      }
      const data = {
        error: {
          message: error.message,
          stack: error.stack,
        },
        userAgent: navigator.userAgent,
        date: format(new Date(), "dd MMM yy - HH:mm:ss"),
        url: window.location?.href,
        user,
      };
      sendCrash(data);

      return <CrashPage />;
    }
    return children;
  }
}

export default ErrorBoundary;
