export const CardActionTypes = {
  GET: {
    CARDS_PENDING: "GET_CARDS_PENDING",
    CARDS_SUCCESS: "GET_CARDS_SUCCESS",
    CARDS_ERROR: "GET_CARDS_ERROR",
  },
  ADD: {
    CARD_PENDING: "ADD_CARD_PENDING",
    CARD_SUCCESS: "ADD_CARD_SUCCESS",
    CARD_ERROR: "ADD_CARD_ERROR",
  },
  EDIT: {
    CARD_PENDING: "EDIT_CARD_PENDING",
    CARD_SUCCESS: "EDIT_CARD_SUCCESS",
    CARD_ERROR: "EDIT_CARD_ERROR",
  },
  DELETE: {
    CARD_PENDING: "DELETE_CARD_PENDING",
    CARD_SUCCESS: "DELETE_CARD_SUCCESS",
    CARD_ERROR: "DELETE_CARD_ERROR",
  },
  DELETE_ERRORS: "DELETE_ERRORS",
};
