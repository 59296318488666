import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import SearchPage from "./components/searchResult/SearchPage";
import Footer from "./components/newFooter/Footer";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import CreatePassword from "./components/sign/CreatePassword";
import UserProfile from "./components/userprofile/UserProfile";
import CategoryDetails from "./components/category/CategoryDetails";
import ProductDetails from "./components/product/ProductDetails";
import VendorsDetails from "./components/vendor/VendorDetails";
import Cart from "./components/cart/Cart";
import Wallets from "./components/wallets/Wallets";
import Payment from "./components/wallets/Payment";
import LoadMoney from "./components/wallets/LoadMoney";
import CreaditCard from "./components/wallets/CreaditCard";
import Club from "./components/club/Club";
import Clubs from "./components/clubs/Clubs";
import Dashboard from "./components/dashboard/Dashboard";
import CategoryList from "./components/category/CategoryList";
import NewMap from "./components/location/NewMap";
import PaymentHistory from "./components/wallets/PaymentHistory";
import Menu from "./components/menu/Menu";
import Checkout from "./components/cart/Checkout";
import Pay from "./components/cart/Pay";
import PaymentCardHistory from "./components/wallets/PaymentCardHistory";
import Company from "./components/booking/Company";
import Product from "./components/booking/Product";
import BookingCart from "./components/booking/BookingCart";
import BookingCartDetail from "./components/booking/BookingCartDetail";
import AllVendor from "./components/vendor/AllVendor";
import BookingPayment from "./components/booking/BookingPayment";
import CategoryProduct from "./components/category/CategoryProduct";
import Header from "./components/header/Header";
import ChatBot from "./components/chat/ChatBot";
import RegisterBusiness from "./components/register/RegisterBusiness";
import Shops from "./components/shop/Shops";
import ManageDashboard from "./components/dashboard/ManageDashboard";
import YourAccount from "./components/userprofile/account/YourAccount";
import ReferralProgram from "./components/userprofile/referralprogram/ReferralProgram";
import Filemanager from "./components/userprofile/filemanage/Filemanager";
import Documents from "./components/userprofile/document/Documents";
import Chatting from "./components/userprofile/chat/Chatting";
import Payments from "./components/userprofile/payment/Payments";
import Gift from "./components/gift/Gift";
import GetGift from "./components/gift/getGift/GetGift";
import Accessibility from "./components/accessibility/Accessibility";
import Terms from "./components/terms/Terms";
import Contact from "./components/contact/Contact";
import Think from "./components/think/Think";
import About from "./components/about/About";
import Prices from "./components/prices/Prices";
import Success from "./components/cart/success";
import Error from "./components/cart/error";
import {
  getGeo,
  getMerchants,
  getUser,
  setSidebarState,
} from "./redux/actions-exporter";
import Sales from "./components/sales/Sales";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";
import InstallPWA from "./components/InstallPWA";
import CookieConsent from "react-cookie-consent";

import "./App.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { OldRouteMap } from "./helpers/OldRouteMap";
import RedirectComponent from "./helpers/RedirectComponent";

function App(props) {
  const dispatch = useDispatch();
  const { isChatOpen } = useSelector((state) => state.chat);
  const [isClub, setIsClub] = useState(false);
  const [isVisiblePWA, setIsVisiblePWA] = useState(false);
  const { user } = useSelector((state) => state.user);
  const geo = useSelector((state) => state.user.geo);
  const [dis, setDis] = useState(4);
  const { products, product } = useSelector((state) => state.product);
  const [searchText, setSearchText] = useState(""); // eslint-disable-line

  useEffect(() => {
    dispatch(getUser());

    document.addEventListener("b24chat.widget.logout", () => {
      dispatch(setSidebarState(false));
      dispatch(getUser());
    });
    document.addEventListener("b24chat.widget.close", () =>
      dispatch(setSidebarState(false))
    );
  }, [dispatch]); // eslint-disable-line

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos, state) {
    dispatch(
      getGeo({
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
        state,
      })
    );
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (pos) => success(pos, result.state),
              errors,
              options
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (pos) => success(pos, result.state),
              errors,
              options
            );
          } else if (result.state === "denied") {
            dispatch(getGeo({ state: "denied" }));
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);
  useEffect(() => {
    // if (products?.length || !window.location.href === '/') {
    const ranges = [
      100, 5000, 10000, 50000,
      // 0
      10000000000,
    ];
    // dispatch(getMerchants(geo.longitude, geo.latitude, ranges[dis], 0, 10));
    // }
  }, [dis, geo.longitude, geo.latitude, products, product]);

  return (
    <ErrorBoundary user={user}>
      <div className={isChatOpen ? "activeChat" : ""}>
        <CookieConsent
          location="bottom"
          buttonText="אין בעיה!"
          cookieName="myAwesomeCookieName2"
          contentClasses="cookieText"
          containerClasses={isVisiblePWA ? "cookie-margin" : "cookie"}
          buttonStyle={{
            background: "var(--primary)",
            fontSize: "13px",
            borderRadius: "10px",
          }}
          expires={150}
        >
          באתר זה נעשה שימוש בעוגיות לטובת שיפור חוויית השימוש{" "}
        </CookieConsent>

        <ServiceWorkerWrapper />
        <InstallPWA setIsVisiblePWA={setIsVisiblePWA} />
        <ToastContainer />
        <BrowserRouter>
          <Header searchText={searchText} setSearchText={setSearchText} />
          <Routes>
            <Route path="/" element={<Dashboard setIsClub={setIsClub} />} />
            <Route
              path="/search"
              element={
                <SearchPage
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              }
            />
            SearchReults
            <Route
              path="/club/:clubId"
              element={<Club setIsClub={setIsClub} />}
            />
            <Route path="/clubs" element={<Clubs />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route
              path="/reset/:token"
              exact
              element={<CreatePassword {...props} />}
            />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/categorydetails" element={<CategoryDetails />} />
            <Route path="/category/:id" element={<CategoryProduct />} />
            <Route
              path="/product/:id"
              element={<ProductDetails {...props} isClub={isClub} />}
            />
            <Route
              path="/vendor/:id"
              element={<VendorsDetails {...props} isClub={isClub} />}
            />
            <Route path="/sales" element={<Sales />} />
            {/* <Route path="/malls" element={<Malls />} /> */}
            {/* <Route path="/malldetials" element={<MallDetials />} /> */}
            {/* <Route path="/mallevent" element={<MallEvent />} /> */}
            <Route path="/cart" element={<Cart />} />
            <Route path="/wallet" element={<Wallets />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/loadmoney" element={<LoadMoney />} />
            {/*<Route path="/loadcredit" element={<LoadMoney credit={true} />} />*/}
            <Route path="/creaditcard" element={<CreaditCard />} />
            <Route path="/shops" element={<Shops />} />
            <Route path="/categorylist" element={<CategoryList />} />
            <Route path="/map" element={<NewMap />} />
            {/* <Route path="/map" element={<TestMap />} /> */}
            <Route path="/paymenthistory" element={<PaymentHistory />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout/pay" element={<Pay />} />
            <Route
              path="/paymentcardhistory"
              element={<PaymentCardHistory />}
            />
            <Route path="/company" element={<Company />} />
            <Route path="/product" element={<Product />} />
            <Route path="/bookingcart" element={<BookingCart />} />
            <Route path="/bookingcartdetail" element={<BookingCartDetail />} />
            <Route
              path="/allvendors"
              element={<AllVendor dis={dis} setDis={setDis} />}
            />
            <Route path="/bookingpayment" element={<BookingPayment />} />
            <Route path="/gift/*" element={<Gift />} />
            <Route path="/getgift" element={<GetGift />} />
            <Route path="/chatbot" element={<ChatBot />} />
            <Route path="/registerbusiness" element={<RegisterBusiness />} />
            <Route path="/managedashboard" element={<ManageDashboard />} />
            <Route path="/youraccount" element={<YourAccount />} />
            <Route path="/referralprogram" element={<ReferralProgram />} />
            <Route path="/filemanager" element={<Filemanager />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/chatting" element={<Chatting />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/accessibility" element={<Accessibility />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/think" element={<Think />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/prices" element={<Prices />} />
            <Route
              path={"/checkout/success/:orderId"}
              element={<Success />}
              exact
            />
            <Route path={"/checkout/error/:orderId"} element={<Error />} />
            {/*Temporary, remove when old site content is migrated here!*/}
            <Route
              path={"/team/*"}
              element={
                <RedirectComponent url="https://sub.raayonit.co.il/team/?p=home/login&d=6" />
              }
            />
            <Route path={"/club/*"} element={<RedirectComponent />} />
            <Route path={"/v/*"} element={<RedirectComponent useOldMap />} />
            <Route
              path={"/r/budget"}
              element={
                <RedirectComponent url="https://sub.raayonit.co.il/Site/he/pages/inPage.asp?page=giftcard&action=checkbudget" />
              }
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
}

export default App;
