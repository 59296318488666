import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Send from "../product/Send";

import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Box,
  TextField,
  DialogTitle,
  Dialog,
  IconButton,
  Alert,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  getCart,
  removeItemFromCart,
  updateCart,
} from "../../redux/API/cart/cart.action";
import { CouponActionTypes } from "../../redux/API/coupon/coupon.types";
import {
  cancelCoupon,
  checkCoupon,
} from "../../redux/API/coupon/coupon.action";
import QuantitySelector from "../quantitySelector";
import widgetHelper from "../../helpers/widget";
import Modal from "@mui/material/Modal";

import "../cart/cart.css";
// import { Alert, AlertTitle } from "@mui/lab";

// css
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 450,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
  textAlign: "right",
};

export default function Cart() {
  const navigate = useNavigate();
  const [cartPop, setCartPop] = useState(false);
  const [sure, setSure] = useState(false);
  const [fors, setFors] = useState([]);
  const [chosen, setChosen] = useState({ product: {}, price: "", quantity: 1 });
  const [show, setShow] = useState(false);
  const [numbersOfItems, setNumbersOfItems] = useState({});
  const { t } = useTranslation();
  const cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.user.user);
  const checkedCoupon = useSelector((state) => state.coupon.checkedCoupon);
  const cancelledCoupon = useSelector((state) => state.coupon.cancelledCoupon);
  const dispatch = useDispatch();
  const [visibilityPromocodeInput, setVisibilityPromocodeInput] =
    useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeActvated, setPromoCodeActvated] = useState(false);
  const [less10Model, setLess10Model] = useState(false);
  const [zeroPricePopup, setZeroPricePopup] = useState(false);

  const handleClose = () => {
    setLess10Model(false);
  };
  // console.log(cart, user);
  const handleUsePromocode = () =>
    setVisibilityPromocodeInput(!visibilityPromocodeInput);
  const handlePromoCodeChange = (e) => setPromoCode(e.target.value);
  const handleSubmitPromocode = () => {
    if (!promoCodeActvated) return dispatch(checkCoupon(promoCode));
    return dispatch(cancelCoupon());
  };

  const handleChangeQuantity = (e, productId, itemId) => {
    let result = { ...numbersOfItems };
    if (result[productId] !== e.target.value || !result[productId]) {
      dispatch(updateCart(itemId, e.target.value));
    }

    result[productId] = e.target.value;
    setNumbersOfItems(result);
  };

  useEffect(() => {
    if (cart?.length && cart[0]?.couponCode) {
      setPromoCodeActvated(true);
      setVisibilityPromocodeInput(true);
      setPromoCode(cart[0]?.couponCode);
    } else {
      dispatch({ type: CouponActionTypes.CANCEL_COUPON_SUCCESS });
    }
  }, [cart]); //eslint-disable-line

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!user?.id) {
      widgetHelper.login(
        // () => navigate("/login"),
        () => navigate("/")
      );
    }
  }, [user, navigate]);

  useEffect(() => {
    if (!checkedCoupon.id) {
      if (promoCode)
        return toast.error(
          // "Invalid promocode"
          "קוד שגוי"
        );
      return;
    }
    setPromoCodeActvated(true);
    dispatch(getCart());
    toast.success(
      // "Promo code has activated!"
      "הקוד הופעל"
    );
  }, [checkedCoupon]); //eslint-disable-line

  useEffect(() => {
    if (!cancelledCoupon) return;
    setPromoCodeActvated(false);
    dispatch(getCart());
    return setPromoCode("");
  }, [cancelledCoupon]); //eslint-disable-line
  // console.log(cart);
  return (
    <>
      {/* <Header /> */}
      <div className="container">
        {/* <Alert severity="error" style={{ direction: "rtl" }}>
          <AlertTitle style={{ direction: "rtl", textAlign: "right" }}>
            הודעה חשובה!
          </AlertTitle>
          <strong>
            זמנית לא ניתן לבצע רכישה אונליין אלא טלפונית בלבד: 039223392
          </strong>
        </Alert> */}
        <div className="cartTemplate">
          <div className="templateHeading">
            <span className="backIcon">
              <ArrowBackIcon
                onClick={() => {
                  navigate("/");
                }}
              />
            </span>
            <h1 className="templateTitle">{t("cart")}</h1>
          </div>
          <ul>
            {cart?.items?.length > 0 ? (
              cart.items
                .sort((a, b) => {
                  if (a.productId === b.productId) {
                    return a.price - b.price;
                  } else {
                    return a.productId - b.productId;
                  }
                })
                .map((item) => {
                  return (
                    <li className="cartProduct-Module__list" key={item.id}>
                      <div
                        className="cartProduct-box__img"
                        style={{
                          backgroundImage: `url(${item.product.image})`,
                        }}
                      ></div>

                      <div className="cartProduct-box">
                        <h6 className="cartProduct-box__brand">
                          {item.product.title}
                        </h6>
                        <div className="cartProduct-box__noImgNoTitle">
                          <div className="cartProduct-box__info">
                            <h5 className="cartProduct-box__name">
                              {item.product.content}
                            </h5>
                            {item.bonusRule && (
                              <div className="cartProduct-box__rule">
                                {t("Discount rule")}: {item.bonusRule.title}
                              </div>
                            )}
                            {!promoCodeActvated ? (
                              <div className="cartProduct-box__details">
                                {item.price && (
                                  <div
                                    className="cartProduct-box__price"
                                    style={{ padding: 0 }}
                                  >
                                    {/* {item?.price || 0}$ */}
                                    <span>
                                      {item?.product?.benefitType === "load" &&
                                        "שווי: "}
                                      {item.quantity > 1 &&
                                        item.quantity +
                                          "x₪" +
                                          item.price +
                                          " =    "}
                                    </span>
                                    {"₪" + item.total}
                                  </div>
                                )}
                                {!item.price && (
                                  <Alert
                                    severity="warning"
                                    sx={{ textAlign: "right" }}
                                  >
                                    נא לשים לב, מוצר זה אינו זמין לרכישה ללקוחות
                                    פרטיים. על מנת שרכישתו תתאפשר, נא להוסיף
                                    לפחות 10 שוברי הטבה לסל
                                  </Alert>
                                )}
                                {item?.product?.benefitType === "load" &&
                                (item?.discountTotal || item?.total) ? (
                                  <div
                                    className="cartProduct-box__price"
                                    // style={{ padding: "2px 0 10px" }}
                                  >
                                    <span>
                                      {"לתשלום: ₪" +
                                        (item?.discountTotal || item?.total)}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {/* <QuantitySelector
                                  onChange={(e) =>
                                    handleChangeQuantity(
                                      e,
                                      item.productId,
                                      item.id
                                    )
                                  }
                                  minValue={1}
                                  value={
                                    numbersOfItems[item.productId] ||
                                    item.quantity
                                  }
                                /> */}
                              </div>
                            ) : (
                              <div
                                className="cartProduct-box__price"
                                style={{ padding: "10px 0" }}
                              >
                                {/* {item.discountPrice}₪ */}
                                {item?.discountPrice && (
                                  <>
                                    {item.quantity > 0 &&
                                      +"₪" + item.discountTotal.toFixed(1)}
                                    <div className="cartProduct-box__price__discount">
                                      {/* {item?.price || 0}₪ */}
                                      {item.quantity > 0 &&
                                        +"₪" + item.total.toFixed(1)}
                                      {item.product.credit
                                        ? " / " +
                                          item?.product?.credit?.toFixed(1) +
                                          " e-credit"
                                        : ""}
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="cartProduct-rightBlock">
                            <div className="cartProduct-box__brandBox">
                              {item.product?.merchant?.image && (
                                <span className="cartProduct-brandImage">
                                  <img
                                    loading="lazy"
                                    src={item.product.merchant.image}
                                    alt=""
                                    height={20}
                                    width={20}
                                    className="img-fluid"
                                  />
                                </span>
                              )}
                            </div>
                            <div
                              className="removeBtn"
                              onClick={() => {
                                navigate(`/product/${item.product.id}`, {
                                  state: { id: item.product.id },
                                });
                              }}
                            >
                              <RemoveRedEyeIcon />
                            </div>
                            <div
                              className="removeBtn"
                              onClick={() => {
                                setSure(item.id);

                                //   dispatch(removeItemFromCart(item.id));
                                //   toast.success(
                                //     "התו הוסר בהצלחה"
                                //   );
                              }}
                            >
                              <DeleteIcon />
                            </div>
                            <div
                              className="removeBtn"
                              onClick={() => {
                                setCartPop(true);
                                setChosen(item);
                              }}
                            >
                              <EditIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <QuantitySelector
                        onChange={(e) =>
                          handleChangeQuantity(e, item.productId, item.benefitType === "load" ? item.price : null)
                        }
                        minValue={1}
                        value={numbersOfItems[item.productId] || item.quantity}
                      /> */}
                    </li>
                  );
                })
            ) : (
              <div
                style={{
                  marginTop: "20vh",
                  textAlign: "center",
                }}
              >
                העגלה ריקה
              </div>
            )}
          </ul>
          {cart?.items?.length > 0 && (
            <div className="summaryCard">
              <div className="summaryCard-tab">
                <div
                  className="summaryCard-tabBtn isLink"
                  onClick={() => setShow(!show)}
                >
                  {t("orderSummary")}
                  <KeyboardArrowDownIcon
                    className={show ? "arrow-rotate-top" : ""}
                  />
                </div>
              </div>
              {show ? (
                !promoCodeActvated ? (
                  <>
                    <ul className="summaryCard-List">
                      <li
                        className="summaryCard-ListItems"
                        style={{ width: "fit-content" }}
                      >
                        <p style={{ marginLeft: "10px" }}>{t("price")}:</p>
                        <div>
                          <span>₪{(cart?.total - cart?.vat).toFixed(2)}</span>
                        </div>
                      </li>
                      {/*{cart?.discountTotal ? (*/}
                      {/*  <li*/}
                      {/*    className="summaryCard-ListItems"*/}
                      {/*    style={{ width: "fit-content" }}*/}
                      {/*  >*/}
                      {/*    <p style={{ marginLeft: "10px" }}>הנחה:</p>*/}
                      {/*    <div>*/}
                      {/*      <span>*/}
                      {/*        -₪*/}
                      {/*        {(cart?.total - cart?.discountTotal).toFixed(2)}*/}
                      {/*        {' '}*/}
                      {/*        {cart?.discountText*/}
                      {/*          ? `(${cart?.discountText})`*/}
                      {/*          : ""}{" "}*/}
                      {/*      </span>*/}
                      {/*    </div>*/}
                      {/*  </li>*/}
                      {/*) : ''}*/}
                      <li
                        className="summaryCard-ListItems"
                        style={{ width: "fit-content" }}
                      >
                        <p style={{ marginLeft: "10px" }}>מע"מ:</p>
                        <div>
                          <span>₪{cart?.vat ? cart?.vat : 0}</span>
                        </div>
                      </li>
                      {/*<li className="summaryCard-ListItems">*/}
                      {/*  <p>{t("additionalProducts")}</p>*/}
                      {/*  <span>$10</span>*/}
                      {/*</li>*/}
                    </ul>
                    <div className="totalList" style={{ width: "fit-content" }}>
                      <span style={{ margin: "0 10px 0 15px" }}>
                        {t("for pay")}:
                      </span>
                      <div>
                        <span>
                          ₪
                          {cart?.discountTotal
                            ? cart?.discountTotal
                            : cart.total}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <ul className="summaryCard-List">
                      <li className="summaryCard-ListItems">
                        <p>{t("subtotal")}</p>
                        <div>
                          <span style={{ marginRight: "10px" }}>
                            ₪{cart.total}
                          </span>
                          <span className="cartProduct-box__price__discount">
                            ₪{cart?.discountTotal || cart.total}{" "}
                            {cart?.discountText
                              ? `(${cart?.discountText})`
                              : ""}
                          </span>
                        </div>
                      </li>
                      {/*<li className="summaryCard-ListItems">*/}
                      {/*  <p>{t("additionalProducts")}</p>*/}
                      {/*  <span>$10</span>*/}
                      {/*</li>*/}
                    </ul>
                    <div className="totalList">
                      <span>{t("total")}</span>
                      <div>
                        <span style={{ marginRight: "10px" }}>
                          ₪{cart.total}
                        </span>
                        <span className="cartProduct-box__price__discount">
                          ₪{cart?.discountTotal || cart.total}{" "}
                          {cart?.discountText ? `(${cart?.discountText})` : ""}
                        </span>
                      </div>
                    </div>
                  </>
                )
              ) : null}
              <div className="cartBtns">
                <Box className="outline-box">
                  {!visibilityPromocodeInput ? (
                    <Button
                      variant="outlined"
                      // startIcon={<PercentIcon />}
                      // endIcon={<NavigateNextIcon />}
                      onClick={handleUsePromocode}
                    >
                      {t("usePromoCode")}
                    </Button>
                  ) : (
                    <>
                      <TextField
                        label={t("Code")}
                        variant="standard"
                        className="promocode-input"
                        value={promoCode}
                        onChange={handlePromoCodeChange}
                        disabled={promoCodeActvated}
                      />
                      <Button onClick={handleSubmitPromocode}>
                        {promoCodeActvated ? "x" : t("usePromoCode")}
                      </Button>
                    </>
                  )}
                </Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (cart?.cartCount < 10) {
                      const isThereZeroPrice = cart.items.some(
                        (item) => !item.price
                      );
                      if (isThereZeroPrice) {
                        setZeroPricePopup(true);
                      } else {
                        const isThereBenefit = (element) =>
                          element.product.benefitType === "benefit";
                        if (!cart?.items.some(isThereBenefit)) {
                          navigate("/checkout", {
                            state: {
                              amount: cart.discountTotal,
                              discount: 1,
                              type: "load_credit",
                              successURL:
                                process.env.REACT_APP_API_PELECARD_LOADING_URL,
                              errorURL:
                                process.env.REACT_APP_API_PELECARD_LOADING_URL,
                            },
                          });
                        } else {
                          setLess10Model(true);
                        }
                      }
                    } else {
                      navigate("/checkout", {
                        state: {
                          amount: cart.discountTotal,
                          discount: 1,
                          type: "load_credit",
                          successURL:
                            process.env.REACT_APP_API_PELECARD_LOADING_URL,
                          errorURL:
                            process.env.REACT_APP_API_PELECARD_LOADING_URL,
                        },
                      });
                    }
                  }}
                >
                  {t("checkout")}
                </Button>
                <Modal
                  open={less10Model}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} width={{ xs: "320px", sm: "450px" }}>
                    <div>
                      <div style={{ marginBottom: "20px", fontSize: "17px" }}>
                        לקוח יקר <br></br> לא השלמת הזמנת 10 שוברים לפיכך
                        המחירים הינם לפי מחירון פרטי
                      </div>
                      <Box
                        sx={{ display: "flex", gap: "20px" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                      >
                        <Box width={{ xs: "unset", sm: "50%" }}>
                          <Button
                            variant="contained"
                            sx={{
                              m: "8px 0",
                              // minHeight: 56,
                              background: "var(--primary)",
                              width: "100%",
                            }}
                            size="large"
                            onClick={handleClose}
                          >
                            חזרה להוספת שוברים
                          </Button>
                          <div style={{ fontSize: "14px" }}>
                            חזור לבחירת מינימום 10 שוברים לקבלת מחיר עסקי
                          </div>
                        </Box>
                        <Box width={{ xs: "unset", sm: "50%" }}>
                          <Button
                            variant="contained"
                            sx={{
                              m: "8px 0",
                              // minHeight: 56,
                              background: "var(--primary)",
                              width: "100%",
                            }}
                            size="large"
                            onClick={() => {
                              navigate("/checkout", {
                                state: {
                                  amount: cart.discountTotal,
                                  discount: 1,
                                  type: "load_credit",
                                  successURL:
                                    process.env
                                      .REACT_APP_API_PELECARD_LOADING_URL,
                                  errorURL:
                                    process.env
                                      .REACT_APP_API_PELECARD_LOADING_URL,
                                },
                              });
                            }}
                          >
                            המשך לתשלום
                          </Button>
                          <div style={{ fontSize: "14px" }}>
                            עבור לעגלת קניות במחיר לקוח פרטי
                          </div>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                </Modal>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={zeroPricePopup}
        onClose={() => setZeroPricePopup(false)}
        maxWidth="sm"
        // fullWidth
        sx={{
          textAlign: "center",
        }}
      >
        <DialogContent>
          נא לשים לב, הרכישה אינה מתאפשרת עם המוצרים הנמצאים בעגלה! אנא השלימו
          את כמות המוצרים בעגלה על מנת לאפשר רכישה כלקוח פרטי, או הסירו את
          המוצרים ללקוחות עסקיים
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              m: "0 auto",
              background: "var(--primary)",
            }}
            size="large"
            onClick={() => setZeroPricePopup(false)}
          >
            אישור
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setCartPop(false)}
        open={cartPop}
        maxWidth="xl"
        fullWidth
        sx={{
          textAlign: "center",
        }}
      >
        <DialogTitle></DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setCartPop(false);
          }}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            zIndex: "10000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Send
          setCartPop={setCartPop}
          isCart={true}
          handleChangeQuantity={handleChangeQuantity}
          chosen={chosen}
          setChosen={setChosen}
          fors={fors}
          setFors={setFors}
        />
      </Dialog>

      <Modal open={sure}>
        <Box sx={style} width={{ xs: "320px", sm: "450px" }}>
          <div>
            <div style={{ marginBottom: "20px", fontSize: "17px" }}>
              האם להסיר את השובר מהעגלה?
            </div>
            <Box
              sx={{ display: "flex", gap: "20px" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Box width={{ xs: "unset", sm: "50%" }}>
                <Button
                  variant="contained"
                  sx={{
                    m: "8px 0",
                    background: "var(--primary)",
                    width: "100%",
                  }}
                  size="large"
                  onClick={() => {
                    dispatch(removeItemFromCart(sure));
                    toast.success("התו הוסר בהצלחה");
                    setSure(false);
                  }}
                >
                  הסרה
                </Button>
              </Box>
              <Box width={{ xs: "unset", sm: "50%" }}>
                <Button
                  variant="contained"
                  sx={{
                    m: "8px 0",
                    background: "var(--primary)",
                    width: "100%",
                  }}
                  size="large"
                  onClick={() => setSure(false)}
                >
                  ביטול
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
}
