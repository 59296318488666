import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Slider } from "@material-ui/core";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormGroup, TextField, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { getRegions, setProductFilter } from "../../redux/actions-exporter";

import "./search.css";

const TRANSPARENCY = 0.6;

const Search = ({
  filterProducts,
  setFilterProducts,
  handleChange,
  clearFilter,
  setClearFilter,
  hidePrice,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [region, setRegion] = useState(""); // eslint-disable-line
  const [city, setCity] = useState("");
  const [go, setGo] = useState(false);
  const [cities, setCities] = useState([]);
  // const [products, setProducts] = useState([]);
  const [minPrice, setMinPrice] = useState(0); // eslint-disable-line
  const [maxPrice, setMaxPrice] = useState(0);
  const b2b = useSelector((state) => state.tabbing.b2b);
  const [filter, setFilter] = useState({
    price: [],
    title: "",
    city: "",
    region: "",
    b2b,
  });
  const products = useSelector((state) => state.product?.products);
  const afterFilter = useSelector((state) => state.product?.filteredProducts);
  const regions = useSelector((state) => state.region?.regions);

  useEffect(() => {
    if (!regions?.length) dispatch(getRegions());
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (clearFilter === true) {
      // setFilter({});
      setFilter({
        price: [minPrice, maxPrice],
        title: "",
        city: "",
        region: "",
        b2b,
      });
      setRegion("");
      setCity("");
      // if (prices.length) {
      //   prices.sort((a, b) => a - b);
      //   if (prices[prices.length - 1] > maxPrice)
      //     setMaxPrice(prices[prices.length - 1]);
      // }
      // setMinPrice(0);
      setClearFilter(false);
    }
  }, [clearFilter]); // eslint-disable-line

  useEffect(() => {
    // if (afterFilter.length > 0) {
    setFilterProducts(afterFilter);
    // console.log(afterFilter);
    // }
  }, [afterFilter]); // eslint-disable-line

  useEffect(() => {
    // setFilter({ ...filter, b2b });
    // setFilter({
    //   price: [minPrice, maxPrice],
    //   title: "",
    //   city: "",
    //   region: "",
    //   b2b,
    // });
    setClearFilter(true);
  }, [b2b]);

  useEffect(() => {
    if (products?.length) {
      const prices = [];
      products.forEach((product) => {
        if (product?.priceB2B) {
          prices.push(parseFloat(product.priceB2B));
        }
      });

      if (prices.length) {
        prices.sort((a, b) => a - b);
        if (prices[prices?.length - 1] > maxPrice) {
          setMaxPrice(Math.ceil(prices[prices.length - 1] / 100) * 100);
          setFilter({
            ...filter,
            price: [0, Math.ceil(prices[prices.length - 1] / 100) * 100],
          });
        }
      }
    }
  }, [products]); // eslint-disable-line

  useEffect(() => {
    let merchantCities = [];
    products?.map((product) =>
      product.cities.map((city) => {
        if (!merchantCities.includes(city) && city.length > 0) {
          merchantCities.push(city);
        }
      })
    );
    setCities(merchantCities.sort());
  }, [products]); // eslint-disable-line

  const onFilter = (e) => {
    // if (filter.title || filter.price || filter.city || filter.region) {
    handleChange("all");
    setGo(true);
  };
  useEffect(() => {
    if (go) {
      dispatch(setProductFilter(filter));
      setGo(false);
    }
  }, [go]);
  // console.log(filter);
  return (
    <div
      className="search"
      style={{ background: `rgba(250, 250, 250, ${TRANSPARENCY + 0.2})` }}
    >
      <FormGroup className="filter" onSubmit={onFilter}>
        {!hidePrice && (
          <div className="filterBlock">
            <div className="priceSlider">
              <span className="priceLabel">
                {(filter?.price ? filter.price : [minPrice, maxPrice]).join(
                  " - "
                )}
              </span>
              <Slider
                onChange={(e, value) => {
                  setFilter({
                    ...filter,
                    price: value,
                  });
                }}
                value={filter?.price || [minPrice, maxPrice]}
                max={maxPrice}
                min={minPrice}
                sx={{ color: "var(--primary)" }}
              />
            </div>
            <h6 className="filterBlockTitle">{t("priceRange")}</h6>
          </div>
        )}
        <div className="searchWrapper" style={{ margin: "auto" }}>
          <FormControl sx={{ width: { xs: "92%", sm: "auto" } }}>
            <TextField
              size="small"
              id="search-bar"
              sx={{ m: 1, background: `rgba(250, 250, 250, ${TRANSPARENCY})` }}
              placeholder={`${t("Search")}`}
              // onChange={(e) => setSearchText(e.target.value)}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  title: e.target.value,
                });
              }}
              value={filter.title}
            />
          </FormControl>

          <FormControl sx={{ m: 1, width: 100 }}>
            <Select
              size="small"
              disabled={filter.city}
              displayEmpty
              className="selectBorder"
              InputLabelProps={{ shrink: false }}
              autoWidth
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                background: `rgba(250, 250, 250, ${TRANSPARENCY})`,
                textAlign: "right",
              }} // onChange={(e) => setArea(e.target.value)}
              onChange={(e) => {
                setRegion(e.target.value);
                setCity("");
                setFilter({
                  ...filter,
                  region: e.target.value,
                  city: "",
                });
              }}
              // value={regions.length ? regions[0].id : ""}
              value={region}
            >
              <MenuItem value="">אזור</MenuItem>
              {regions.length > 0
                ? regions.map((region) => (
                    <MenuItem value={region.id}>{region.name}</MenuItem>
                  ))
                : ""}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, maxWidth: 100 }}>
            <Select
              size="small"
              disabled={filter.region}
              displayEmpty
              className="selectBorder"
              InputLabelProps={{ shrink: false }}
              autoWidth
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                background: `rgba(250, 250, 250, ${TRANSPARENCY})`,
                // direction: "ltr"
              }}
              onChange={(e) => {
                setCity(e.target.value);
                setRegion("");
                setFilter({
                  ...filter,
                  city: e.target.value,
                  region: "",
                });
              }}
              value={city}
            >
              <MenuItem value="">ישוב</MenuItem>
              {cities.length > 0
                ? cities.map((item) => <MenuItem value={item}>{item}</MenuItem>)
                : ""}
            </Select>
          </FormControl>
          {/*/!*<FormControl sx={{ m: 1, maxWidth: 120 }}>*!/ TODO: Think this over, there's already a category swiper.*/}
          {/*  <Select*/}
          {/*    displayEmpty*/}
          {/*    InputLabelProps={{ shrink: false }}*/}
          {/*    autoWidth*/}
          {/*    inputProps={{ "aria-label": "Without label" }}*/}
          {/*    sx={{ background: "#f9f9f9", direction: "ltr" }}*/}
          {/*    onChange={(e) => setCategory(e.target.value)}*/}
          {/*    value={category}*/}
          {/*  >*/}
          {/*    <MenuItem value="קטגוריה">קטגוריה</MenuItem>*/}
          {/*    {productCategories.map((category) => {*/}
          {/*      return (*/}
          {/*          <MenuItem key={category.id} value={category.value} onClick={()=>{setCategory(category.title)}}>*/}
          {/*            {category.title}*/}
          {/*          </MenuItem>*/}
          {/*      )*/}
          {/*    })}*/}
          {/*  </Select>*/}
          {/*</FormControl>*/}

          <Button
            variant="contained"
            sx={{
              m: 1,
              minHeight: 40,
              background: "var(--primary)",
              maxWidth: 120,
            }}
            size="small"
            onClick={onFilter}
            type="submit"
          >
            {t("apply")}
          </Button>
        </div>
      </FormGroup>
    </div>
  );
};

export default Search;
