import React from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, TextField, MenuItem, Select } from "@mui/material";
import { Button } from "@material-ui/core";
// import AddIcon from "@mui/icons-material/Add";
// import mask from "../../assets/images/mask.png";
import aIcon from "../../assets/icons/a-icon.png";

const amount = Array.from(Array(100), (_, index) => index + 1);
const categoriesMap = {
  7257: 617, // restaurant
  7260: 618, // boker
  7262: 628, // mapa
  7256: 617,
  7261: 626, // asiatit,
  7259: 618,
  7273: 617,
};

const ProductCard = ({
  product,
  b2b,
  buttonOnClick,
  chosen,
  setChosen,
  disableCategory = false,
}) => {
  const navigate = useNavigate();
  // console.log({ product });
  const chosenCategory = product.categories.find(
    (cat) => cat.id === categoriesMap[product.id]
  );
  return (
    <li className={`product-Module__list isLink`}>
      <div className="product-box">
        <div
          onClick={() => {
            navigate(`/product/${product.id}`, {
              state: { id: product.id },
            });
          }}
          className="product-box__img"
          style={{
            backgroundImage: `url(${product.image})`,
          }}
          loading="lazy"
        >
          {/* <div style={{ position: "absolute", bottom: -30 }}>
            <img alt="" src={mask} style={{ width: "100%" }} />
          </div> */}
          {!disableCategory && (
            <div className="product-box__tag">
              {product.categories?.length > 1 && !chosenCategory && (
                <div
                  style={{
                    backgroundImage: `url(${aIcon})`,
                    backgroundSize: "contain",
                    height: 25,
                    width: 25,
                    padding: 15,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 12,
                    lineHeight: 1.3,
                    textAlign: "center",
                  }}
                >
                  מתנה משולבת
                </div>
              )}
              {(product.categories?.length === 1 || chosenCategory) && (
                <div
                  style={{
                    backgroundImage: `url(${aIcon})`,
                    backgroundSize: "contain",
                    height: 25,
                    width: 25,
                    padding: 15,
                  }}
                >
                  <img
                    alt=""
                    src={
                      (chosenCategory && chosenCategory.image) ||
                      product.categories[0].image
                    }
                    style={{
                      filter: "grayscale(1) brightness(0)",
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="product-box__description">
          <h6 className="product-box__brand" style={{ textAlign: "center" }}>
            {product.title}
          </h6>
        </div>

        <div className="product-box__infoTop">
          {(b2b ? product.priceB2B : product.price) > 0 ? (
            <div className="fixed">
              ₪{b2b ? product.priceB2B : product.price}{" "}
            </div>
          ) : (
            <>
              <FormControl sx={{ maxWidth: 60 }}>
                <TextField
                  required
                  type="tel"
                  size="small"
                  className="priceInputField"
                  id="search-bar"
                  value={chosen?.product?.id === product.id ? chosen.price : ""}
                  onChange={(e) =>
                    setChosen({
                      ...chosen,
                      product: product,
                      price: e.target.value,
                    })
                  }
                  placeholder="סכום"
                  inputProps={{
                    step: 0.1,
                    min: 0,
                    style: {
                      fontSize: 12,
                      minHeight: "23px",
                      width: "55px",
                      padding: "8px 6px 8px 4px",
                    },
                  }}
                />
              </FormControl>
              ₪
            </>
          )}
          <FormControl size="small" sx={{ minWidth: 60 }}>
            <Select
              autoWidth
              value={chosen?.product?.id === product.id ? chosen.quantity : 1}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              InputLabelProps={{ shrink: false }}
              onChange={(e) =>
                setChosen({
                  ...chosen,
                  product: product,
                  quantity: e.target.value,
                })
              }
            >
              {amount.map((i, idx) => (
                <MenuItem value={i} key={idx}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={() => buttonOnClick(product)}
            sx={{ color: "var(--blue)", borderColor: "var(--blue)" }}
          >
            לרכישה
          </Button>
        </div>
      </div>
    </li>
  );
};

export default ProductCard;
