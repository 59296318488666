import "./about.css";
function About() {
  return (
    <div>
      <div
        data-elementor-type="wp-page"
        data-elementor-id="4988"
        className="elementor elementor-4988"
        data-elementor-settings="[]"
      >
        <div className="elementor-section-wrap">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-eb64288 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="eb64288"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-965badf"
                data-id="965badf"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-9e474fc elementor-widget elementor-widget-heading"
                    data-id="9e474fc"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        <strong>אודות רעיונית שוברי מתנה</strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-da10c3a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="da10c3a"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6cf4b85"
                data-id="6cf4b85"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-3801231 elementor-widget elementor-widget-text-editor"
                    data-id="3801231"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div className="elementor-widget-container">
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          חברת רעיונית בע"מ הינה החברה המובילה בישראל בתחום
                          הרווחה, מתנות לעובדים, שי לחג, וקידום המכירות באמצעות
                          שוברי מתנה והזמנות לנופש, שוברים לספא, מסעדות, קולנוע,
                          ימי כיף ועוד.
                        </span>
                      </p>
                    </div>
                    <div>
                      <h5>
                        <strong>
                          פעילות החברה בתחום מתנות לעובדים ושי לחג
                        </strong>
                      </h5>
                      <p>
                        החברה פועלת מעל 30 שנים ומציעה מגוון רחב של שוברי הזמנה
                        איכותיים ברמות מחיר שונות, אותם את/ה, כמנהל/ת, תוכל לחלק
                        לעובדים, לקוחות, ספקים ולכל מי שתבחר. שוברי ההזמנה של
                        חברת רעיונית מכובדים ישירות וללא הגבלה במאות אתרים,
                        שוברים לבתי מלון, בתי אירוח כפריים, מסעדות, חנויות,
                        רשתות ובתי קולנוע ברחבי הארץ.
                      </p>
                      <h5>
                        <strong>מי נהנה משירותי רעיונית?</strong>
                      </h5>
                      <p>
                        בין אלפי לקוחותינו נמנות חברות מהשורה הראשונה בארץ, שכבר
                        משתמשות ונהנות ממוצרים ייחודיים ואיכותיים: חברות כרטיסי
                        אשראי, חברות ביטוח, בנקים, חברות היי-טק, חברות תקשורת,
                        חברות כח אדם ואחרות.
                      </p>

                      <h5>
                        <strong>שירותים נוספים</strong>
                      </h5>
                      <p>
                        כמו כן, אנו נותנים שירות מיוחד לימי כיף, טיולים מאורגנים
                        לקבוצות, מבצעי קד"מ לחברות והטבות למועדוני לקוחות.
                      </p>

                      <h5>
                        <strong>רוצה להזמין?</strong>
                      </h5>
                      <p>
                        התקשרו אלינו, או פנו דרך האתר, ונעזור לך להרכיב חבילת
                        מתנות/קד"מ המותאמת לתקציב שלך. לחצו כאן למעבר לעמוד
                        יצירת קשר עם רעיונית
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default About;
