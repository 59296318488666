import { CardActionTypes } from "./card.types";

const INITIAL_STATE = {
  isGetCardsPending: false,
  cards: [],
  cardsError: null,

  isAddCardPending: false,
  addCard: {},
  addCardError: null,

  isRemoveCardPending: false,
  removeCard: {},
  removeCardError: null,
};

const CardReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case CardActionTypes.GET.CARDS_PENDING:
      return {
        ...state,
        isGetCardsPending: true,
        cards: [],
      };

    case CardActionTypes.GET.CARDS_SUCCESS:
      return {
        ...state,
        isGetCardsPending: false,
        cards: action.payload,
        userError: null,
      };

    case CardActionTypes.GET.CARDS_ERROR:
      return {
        ...state,
        isGetCardsPending: false,
        cards: [],
        cardsError: action.payload,
      };

    case CardActionTypes.ADD.CARD_PENDING:
      return {
        ...state,
        isAddCardPending: true,
        addCard: {},
      };

    case CardActionTypes.ADD.CARD_SUCCESS:
      return {
        ...state,
        isAddCardPending: false,
        addCard: action.payload,
        cards: [...state.cards, action.payload],
        addCardError: null,
      };

    case CardActionTypes.ADD.CARD_ERROR:
      return {
        ...state,
        isAddCardPending: false,
        addCard: {},
        addCardError: action.payload,
      };

    case CardActionTypes.DELETE.CARD_PENDING:
      return {
        ...state,
        isRemoveCardPending: true,
        removeCard: {},
      };

    case CardActionTypes.DELETE.CARD_SUCCESS:
      return {
        ...state,
        isRemoveCardPending: false,
        removeCard: action.payload,
        cards: state.cards.filter((card) => card.id !== action.payload),
        removeCardError: null,
      };

    case CardActionTypes.DELETE.CARD_ERROR:
      return {
        ...state,
        isRemoveCardPending: false,
        removeCard: {},
        removeCardError: action.payload,
      };

    case CardActionTypes.DELETE_ERRORS:
      return {
        ...state,
        cardsError: null,
        addCardError: null,
        removeCardError: null,
      };

    default:
      return state;
  }
};

export default CardReducer;
