import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

import { getClubs, getMerchantCategories } from "../../redux/actions-exporter";

import "./clubs.css";

export default function Clubs() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const merchants = useSelector((state) => state.merchant.merchants);
  const merchantsPending = useSelector(
    (state) => state.merchant.isMerchantsPending
  );
  const [selectedTab, setSelectedTab] = useState({ id: "all", title: "all" }); // eslint-disable-line
  const [categories, setCategories] = useState([]);
  const [merchantsList, setMerchantsList] = useState(merchants); // eslint-disable-line
  // const [filterMerchants, setFilterMerchants] = useState([]); // eslint-disable-line

  useEffect(() => {
    dispatch(getClubs());
    dispatch(getMerchantCategories());
  }, [dispatch]); // eslint-disable-line

  // useEffect(() => {
  //   setFilterMerchants(merchants);
  // }, [dbMerchants]); // eslint-disable-line

  useEffect(() => {
    if (categories.length) return;
    //eslint-disable-next-line
    merchants?.map((merchant) => {
      //eslint-disable-next-line
      if (!merchant?.productCategories?.length) return;
      setCategories([...categories, ...merchant.productCategories]);
    });
  }, [merchants]); //eslint-disable-line

  useEffect(() => {
    if (selectedTab !== "all" && merchantsPending) return;
    return setMerchantsList(merchants);
  }, [merchantsPending]); //eslint-disable-line

  return (
    <div>
      <div className="pageTemplate">
        <div className="container">
          <div className="vendorAllListBlock">
            <h6 className="module-heading__title">המועדונים שלנו </h6>
            <ul className="categoryList">
              {merchants.length > 0 ? (
                <div className="club-block">
                  <ul className="club-Module">
                    {merchants.length > 0
                      ? merchants.map((club) => (
                          <li
                            className="club-box"
                            onClick={() => navigate(`/club/${club.id}`)}
                          >
                            <img
                              loading="lazy"
                              alt=""
                              className="club-box__img"
                              src={club.image}
                            />
                          </li>
                        ))
                      : t("Loading, please wait...")}
                  </ul>
                </div>
              ) : merchantsPending ? (
                <CircularProgress />
              ) : (
                t("Loading, please wait...")
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
