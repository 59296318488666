import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./SearchResult.css";

const SearchReult = ({ searchText }) => {
  const navigate = useNavigate();
  const [filterProducts, setFilterProducts] = useState([]); // eslint-disable-line
  const [filterStores, setFilterStores] = useState([]);
  const products = useSelector((state) => state.product?.products);
  const merchants = useSelector((state) => state.merchant.merchants);
  const { pathname } = useLocation();

  window.onload = () => {
    document.onclick = function (e) {
      if (e.target.className !== "menuSearch" && e.target.id !== "search-bar") {
        document.querySelector(".menuSearch").style.display = "none";
      }
    };
  };
  useEffect(() => {
    const filteredP = products.filter((product) => {
      return (
        product.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
        product.content?.toLowerCase().includes(searchText?.toLowerCase()) ||
        product.categories?.some((el) =>
          el.title.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });
    filteredP === products
      ? setFilterProducts([])
      : setFilterProducts(filteredP);

    const filteredS = merchants.filter((store) => {
      return (
        store.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
        store.content?.toLowerCase().includes(searchText?.toLowerCase()) ||
        store.network?.name.toLowerCase().includes(searchText?.toLowerCase()) ||
        store.productsCategories?.some((el) =>
          el.title?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    filteredS === merchants ? setFilterStores([]) : setFilterStores(filteredS);
  }, [searchText]);

  return (
    <div className={`menuSearch ${pathname === "/search" ? "hide" : ""}`}>
      <div className="menuSearchSection">
        <div className="menuSearchSectionLink">שוברים</div>
        <div className="MenuSearchItemsDiv">
          {filterProducts.map((el, i) => {
            return (
              <div
                onClick={() => {
                  navigate(`/product/${el.id}`, {
                    state: { id: el.id },
                  });
                }}
                className="menuSearchItem"
                key={i}
              >
                <img
                  loading="lazy"
                  alt=""
                  className="menuSearchItemImg"
                  src={el.image}
                />
                <div className="menuSearchItemText">{el.title}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="menuSearchSection">
        <div to={"#"} className="menuSearchSectionLink">
          ספקים
        </div>
        <div className="MenuSearchItemsDiv">
          {filterStores.map((el, i) => {
            return (
              <div
                onClick={() => {
                  navigate(`/vendor/${el.id}`, {
                    state: { id: el.id },
                  });
                }}
                className="menuSearchItem"
                key={i}
              >
                <img
                  loading="lazy"
                  alt=""
                  className="menuSearchItemImg"
                  src={el.image}
                />
                <div className="menuSearchItemText">{el.title}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div to="/search" className="menuSearchMore">
        לעוד תוצאות
      </div>
    </div>
  );
};

export default SearchReult;
