import { useLocation } from "react-router-dom";
import { OldRouteMap } from "./OldRouteMap";
import { useEffect } from "react";

/** Emergency component, use only when redirecting by other means is not possible!
 *
 * @param url - URL to redirect to. Leave blank for custom logic.
 * @param useOldMap - Only used for specific routes from the old site.
 * @returns JSX.Element - Empty JSX, just redirects.
 */
const RedirectComponent = ({ url, useOldMap = false }) => {
  /* So far, 3 variations exist:
   * Type A: Links with /v/, shortened links for old products, we just need to map them to the new links
   * Type B: Links to the 'team' page, they come with the url object, so the first useEffect never triggers. Simple redirect.
   * Type C: Links with /club/, essentially just replace 'raayonit.co.il' with 'sub.raayonit.co.il' with useLocation.
   */

  const location = useLocation();
  const map = OldRouteMap; //exported from helpers

  useEffect(() => {
    if (!url) {
      // Type A
      if (useOldMap)
        url =
          "https://www.raayonit.co.il/product/" +
          map[location.pathname.split("/")[2]];
      // Type C
      else
        url =
          "https://sub.raayonit.co.il" + location.pathname + location.search;
    }
  }, []);

  useEffect(() => {
    if (url) window.location.replace(url);
  }, [url]);

  return <></>; // This component doesn't render anything
};

export default RedirectComponent;
