import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import cx from "classnames";

export default function Fields({ handleChange, categories, currentCategory }) {
  return (
    <>
      <h6
        style={{
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        סינון לפי תחומים
      </h6>
      <Swiper
        style={{ padding: "10px" }}
        slidesPerView={"auto"}
        spaceBetween={10}
        centeredSlides={false}
        centerInsufficientSlides={true}
        speed={1000}
        navigation
        modules={[Navigation]}
      >
        <SwiperSlide
          value="all"
          style={{ width: "100px" }}
          onClick={() => handleChange("all")}
          className="categoryList__block isLink"
        >
          <div className="category-box1 text-center">
            <h6
              className={cx("category-box__title", {
                "category-box__active": currentCategory === "all",
              })}
            >
              הכל
            </h6>
          </div>
        </SwiperSlide>
        {categories?.map((category, index) => (
          <SwiperSlide
            style={{ width: "fit-content" }}
            onClick={() => handleChange(category)}
            className="categoryList__block isLink"
          >
            <div className="category-box1 text-center">
              <h6
                className={cx("category-box__title", {
                  "category-box__active": currentCategory === category,
                })}
              >
                {category}
              </h6>
            </div>
          </SwiperSlide>
        ))}
        <SwiperSlide style={{ width: "30px" }}></SwiperSlide>
      </Swiper>
    </>
  );
}
